import { useState, useEffect } from "react";
import { AddLayout } from "../../../layouts";
import { Grid, TextField, Autocomplete, Button } from "../../../components";
import { InputErros, PermisoesHelper } from "../../../helpers";
import { responseErros, voltar } from "../../../utils";
import {
  PerfilsUsuariosService,
  ModulosService,
  notification,
} from "../../../services";
import { perfilUsuarioValidator } from "./middlewares";
import { PerfilUsuario } from "./entities";
import {
  PermissoesActionsComponent,
  PermissoesFormComponent,
} from "./components";
import { useImportContext } from "../../../contexts/import.context";
import {
  montarPermissoesEdicao,
  montarPermissoesCadastro,
} from "./add-perfil-usuario";

const AddPerfilUsuario = ({ match }) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [perfilUsuarioCopia, setPerfilUsuarioCopia] = useState(null);
  const [perfilUsuarios, setPerfilUsuarios] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [permissoesModulos, setPermissoesModulos] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const perfilsUsuariosService = new PerfilsUsuariosService();
  const modulosService = new ModulosService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("perfis-usuarios-visualizar");
  async function buscarDadosIniciais() {
    if (id) {
      const permissoesCadastro = await buscarModulos();
      await buscaPerfil(id, permissoesCadastro);
    } else {
      buscarModulos();
      buscaListaPerfis();
    }
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  const buscaListaPerfis = () => {
    const filtros = {
      padrao: "false",
    };
    perfilsUsuariosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setPerfilUsuarios(result.data.rows);
      }
    });
  };

  const buscaPerfil = async (perfilUsuarioId, permissoesCadastro) => {
    setLoading(true);
    const result = await perfilsUsuariosService.getById(perfilUsuarioId);
    if (!result.isAxiosError) {
      if (!result.data) {
        initialCollapses();
        voltar();
        return;
      }
      setPermissoesModulos(
        montarPermissoesEdicao(
          permissoesCadastro || montarPermissoesCadastro(modulos),
          result.data.permissoesModulos
        )
      );
      preencherFormPerfilUsuario(result.data);
      setLoading(false);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  function preencherFormPerfilUsuario(perfilUsuario) {
    if (id) {
      document.getElementById("nome").value = perfilUsuario?.nome;
    } else {
      setPerfilUsuarioCopia(perfilUsuario);
    }
  }

  const buscarModulos = async () => {
    setLoading(true);
    const result = await modulosService.getAll();
    if (!result.isAxiosError) {
      setModulos(result.data);
      setLoading(false);
      return preencherFormPermissoes(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  function preencherFormPermissoes(modulos) {
    if (!id) {
      setPermissoesModulos(montarPermissoesCadastro(modulos));
    } else {
      return montarPermissoesCadastro(modulos, id);
    }
  }

  const onClickSalvarPerfil = async (indiceSelecionado) => {
    const perfilUsuarioDto = new PerfilUsuario(
      document.getElementById("nome").value,
      permissoesModulos
    );
    perfilUsuarioValidator
      .validate(perfilUsuarioDto, { abortEarly: false })
      .then(async () => {
        if (id) {
          atualizarPerfil(perfilUsuarioDto);
        } else {
          salvarPerfil(indiceSelecionado, perfilUsuarioDto);
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const salvarPerfil = async (indiceSelecionado, perfilUsuarioDto) => {
    setLoading(true);
    const res = await perfilsUsuariosService.cadastrar(perfilUsuarioDto);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.cadastroSucesso();
      if (indiceSelecionado === 0) {
        voltar();
      } else {
        await buscarModulos();
      }
    } else {
      responseErros(res);
    }
  };

  const atualizarPerfil = async (perfilUsuarioDto) => {
    setLoading(true);
    const res = await perfilsUsuariosService.atualizar(id, perfilUsuarioDto);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.alteracaoSucesso();
      voltar();
    } else {
      responseErros(res);
    }
  };

  const onChangePerfilUsuarioCopia = async (_, newValue) => {
    if (newValue?.id) {
      await buscaPerfil(newValue.id);
    } else {
      setPerfilUsuarioCopia(null);
      setPermissoesModulos(
        montarPermissoesEdicao(montarPermissoesCadastro(modulos))
      );
    }
  };

  function onClickAdicionarTodasPermissoes() {
    setPermissoesModulos((prevState) => {
      return Object.keys(prevState).reduce((acc, secao) => {
        acc[secao] = Object.keys(prevState[secao]).reduce(
          (subAcc, subSecao) => {
            subAcc[subSecao] = Object.keys(prevState[secao][subSecao]).reduce(
              (permAcc, nomePermissao) => {
                const permissao = prevState[secao][subSecao][nomePermissao];
                permAcc[nomePermissao] = { ...permissao, habilitado: true };
                return permAcc;
              },
              {}
            );
            return subAcc;
          },
          {}
        );
        return acc;
      }, {});
    });
    notification.sucessoGenericos("Permissões Adicionadas");
  }

  return (
    <AddLayout
      id={id}
      title={id ? "Editar Perfil Usuario" : "Cadastrar Perfil Usuario"}
      onClickSalvar={onClickSalvarPerfil}
      loading={loading}
      disableAtualizar={!permissoesHelper.temPermisao("perfis-usuarios-editar")}
      userLog={id ? {} : null}
      codigo={id ? `Código Nº ${id}` : null}
      actionsTotalizadores={<PermissoesActionsComponent />}
    >
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3}>
          <TextField
            id="nome"
            name="nome"
            label="Nome"
            variant="outlined"
            fullWidth
            required
            error={inputErros.get("nome")}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="perfilUsuarioCopia"
            name="perfilUsuarioCopia"
            disabled={id ? true : false}
            getOptionLabel={(option) => option?.nome ?? ""}
            options={perfilUsuarios}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Perfil de Usuário Copia"
                variant="outlined"
              />
            )}
            value={perfilUsuarioCopia}
            onChange={onChangePerfilUsuarioCopia}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            type="button"
            onClick={onClickAdicionarTodasPermissoes}
            variant="contained"
            className="button-add"
            color="primary"
            sx={{ height: "52px" }}
          >
            ADICIONAR TODAS PERMISSÕES
          </Button>
        </Grid>
      </Grid>
      <PermissoesFormComponent
        permissoesModulos={permissoesModulos}
        setPermissoesModulos={setPermissoesModulos}
      />
    </AddLayout>
  );
};

export default AddPerfilUsuario;
