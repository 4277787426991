import React, { useState, useEffect, useRef } from "react";
import {
  CardContent,
  Grid,
  Paper,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  Autocomplete,
  DatePicker,
  TabPanel,
  FullscreenDialog,
  Button,
  Tooltip,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  notification,
  NfSaidaService,
  OperacaoFiscalService,
  PessoaService,
  CondicoesPagamentoService,
  MeioPagamentoService,
  ProdutosService,
  TabelaPrecoService,
  SessionService,
  OrigemMercadoriaService,
  SituacaoTributariaIpiService,
  history,
  ContasReceberService,
  ColaboradorService,
} from "../../../services";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import {
  responseErros,
  formatDate,
  unformatPrice,
  voltar,
  userLogs,
  cacheApi,
} from "../../../utils";
import { nfSaidaValidator, enderecoValidator } from "./middlewares";
import {
  NfSaidaItens,
  NfSaidaPagamentos,
  NfSaidaDadosClientes,
  NfSaidaInformacoes,
  NfSaidaTributos,
  ButtonMenu,
  ResumoTotal,
  NfSaidaTransporte,
  CancelamentoDialog,
  ErrosDfeDialog,
  IdentificarClienteDialog,
  CartaCorrecaoDialog,
  CopiarNfSaidaDialog,
} from "./components";
import { useImportContext } from "../../../contexts/import.context";
import AddPessoas from "../../pessoas/addPessoa/addPessoa.view";
import { NfSaida } from "../entities";
import {
  tabProps,
  totalItensLista,
  verificaBaixaAutomatica,
  encontrarEnderecoPadrao,
  mapearEnderecos,
  compararPagamentosTotalNfSaida,
  mapearEndereco,
} from "./add-nf-saida";
import shortid from "shortid";
import { useCacheContext } from "../../../contexts/cache.context";

const contasReceberService = new ContasReceberService();
const sessionService = new SessionService();
const nfSaidaService = new NfSaidaService();
const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const colaboradorService = new ColaboradorService();
const produtosService = new ProdutosService();
const condicoesPagamentoService = new CondicoesPagamentoService();
const meioPagamentoService = new MeioPagamentoService();
const tabelaPrecoService = new TabelaPrecoService();
const origemMercadoriaService = new OrigemMercadoriaService();
const situacaoIpiService = new SituacaoTributariaIpiService();
let nfSaidaEntity = {
  dataEmissao: formatDate.toSend(new Date()),
  modalidadeFrete: "mfSemFrete",
  freteCompoeNota: false,
};

const AddNfSaida = ({ match }) => {
  const documentoImportado = window.location.pathname
    .split("/")
    .pop()
    .includes("importar");
  const id = !documentoImportado && match.params.id;
  const { initialCollapses } = useImportContext();
  const { cacheDto, setCacheDto } = useCacheContext();
  const [nfSaida, setNfSaida] = useState(nfSaidaEntity);
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("nf-saida-visualizar");
  const inputCliente = useRef();
  const dadosEmpresa = sessionService.getEmpresaAndUsuario();
  const [loading, setLoading] = useState(true);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [userLog, setUserLog] = useState({});
  const [isCadastroPessoaDialogOpen, setIsCadastroPessoaDialogOpen] =
    useState(false);
  const [identificarClienteDialogOpen, setIdentificarClienteDialogOpen] =
    useState(false);
  const [clienteSemCadastro, setClienteSemCadastro] = useState(false);
  const [errosDfeDialogOpen, setErrosDfeDialogOpen] = useState(false);
  const [errosDfe, setErrosDfe] = useState(null);
  const [abaValue, setAbaValue] = useState(0);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [operacaoFiscal, setOperacaoFiscal] = useState({});
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [transportadoraList, setTransportadoraList] = useState([]);
  const [cidadeId, setCidadeId] = useState(null);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [transportadoraSelecionada, setTransportadoraSelecionada] =
    useState(null);
  const [tabelaPrecoCliente, setTabelaPrecoCliente] = useState([]);
  const [endereco, setEndereco] = useState({
    padrao: false,
  });
  const [enderecoCliente, setEnderecoCliente] = useState(null);
  const [enderecoClienteDataGrid, setEnderecoClienteDataGrid] = useState([]);
  const [enderecoAleterado, setEnderecoAleterado] = useState(null);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [meioPagamentoSelecionado, setMeioPagamentoSelecionado] = useState({});
  const [produtosSimilares, setProdutosSimilares] = useState([]);
  const [frete, setFrete] = useState(null);
  const [configuracoes, setConfiguracoes] = useState({});
  const [nfSaidaProdutos, setNfSaidaProdutos] = useState([]);
  const [nfSaidaPagamentos, setNfSaidaPagamentos] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const nfSaidaValidatorService = nfSaidaValidator(
    operacaoFiscal,
    meioPagamentoSelecionado
  );
  const [origensMercadoriasList, setOrigensMercadoriasList] = useState([]);
  const [situacaoIpiList, setSituacaoIpiList] = useState([]);
  const [dadosImpressao, setDadosImpressao] = useState(null);
  const [listaNotasCredito, setListaNotasCredito] = useState([]);
  const [openCancelamentoDialog, setOpenCancelamentoDialog] = useState(false);
  const [openNotasCreditoDialog, setOpenNotasCreditoDialog] = useState(false);
  const [meioPagamentoCredito, setMeioPagamentoCredito] = useState({});
  const [notasCredito, setNotasCredito] = useState([]);
  const [openCartaCorrecaoDialog, setOpenCartaCorrecaoDialog] = useState(false);
  const [openCopiarNfSaidaDialog, setOpenCopiarNfSaidaDialog] = useState(false);
  const [colaboradorVendedor, setColaboradorVendedor] = useState(null);
  const [unidadeList, setUnidadeList] = useState([]);
  const [gruposTributacaoList, setGruposTributacaoList] = useState([]);
  const [csoSimplesList, setCsoSimplesList] = useState([]);
  const [enquadramentoLegalList, setEnquadramentoLegalList] = useState([]);

  const tiposPessoa = [
    {
      tipoId: 1,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 2,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 3,
      setLista: (dados) => setTransportadoraList(dados),
    },
    {
      tipoId: 4,
      setLista: (dados) => setVendedorList(dados),
    },
  ];

  const buscarConfiguracao = async () => {
    if (dadosEmpresa.empresa.configuracoes) {
      const data = dadosEmpresa.empresa.configuracoes;
      setConfiguracoes(data);
      if (data?.operacaoFiscalVendasPadraoId) {
        await buscaDadosOperacoesFiscais(data.operacaoFiscalVendasPadraoId);
      }
    } else {
      await buscaDadosOperacoesFiscais();
    }
  };

  async function buscaDadosIniciais() {
    if (!id && !documentoImportado) {
      await buscarConfiguracao();
      nfSaidaEntity = new NfSaida({
        dataEmissao: formatDate.toSend(new Date()),
        modalidadeFrete: "mfSemFrete",
        freteCompoeNota: false,
      });
      await buscarPessoaVendedor();
    } else {
      await buscarNfSaida();
    }
    buscarCondicoesPagamento();
    buscarMeiosPagamento();
    buscarVendedores();
    buscaSituacaoIpi();
    buscarOrigensMercadoria();
  }

  const buscarPessoaVendedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === dadosEmpresa.usuario.id
      );
      if (colaboradorVendedorUsuario) {
        nfSaidaEntity.setColaboradorVendedorUsuario(
          colaboradorVendedorUsuario.pessoa.id,
          colaboradorVendedorUsuario.bancoCaixaId,
          permissoesHelper.temPermisao("utilizar-caixa-colaborador")
        );
        setColaboradorVendedor(colaboradorVendedorUsuario);
        setNfSaida(nfSaidaEntity);
      }
    } else {
      responseErros(result);
    }
  };

  async function buscaDadosOperacoesFiscais(idOperacaoFiscalVenda) {
    let filtrosOperacoesFiscais = {
      ativado: true,
      nonPaginated: true,
      tipoOperacao: "SAIDA",
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
      restritiva: true,
      porIntervalo: [],
    };
    if (nfSaidaEntity?.chaveAcessoReferencia) {
      filtrosOperacoesFiscais.finalidade = nfSaidaEntity?.finalidade;
    }
    if (nfSaidaEntity?.documentoDevolucaoCliente) {
      filtrosOperacoesFiscais.tipoOperacao = "ENTRADA";
      filtrosOperacoesFiscais.finalidade = "DEVOLUCAO";
    }
    if (nfSaidaEntity?.documentoDevolucaoFornecedor) {
      filtrosOperacoesFiscais.finalidade = "DEVOLUCAO";
    }
    if (nfSaidaEntity?.operacaoEntrada) {
      filtrosOperacoesFiscais.tipoOperacao = "ENTRADA";
      delete filtrosOperacoesFiscais.finalidade;
    }
    await buscarOperacoesFiscais(
      filtrosOperacoesFiscais,
      idOperacaoFiscalVenda
    );
  }

  const buscarOrigensMercadoria = () => {
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: origemMercadoriaService.getAll(),
    };
    cacheApi("origemmercadoria", cacheBody).then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriasList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscaSituacaoIpi = () => {
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: situacaoIpiService.getAll(),
    };
    cacheApi("situacaoipi", cacheBody).then((result) => {
      if (!result.isAxiosError) {
        setSituacaoIpiList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    buscaDadosIniciais().then(setLoading(false));
  }, []);

  const buscarNfSaida = async () => {
    if (id) {
      const response = await nfSaidaService.getById(id);
      if (!response.isAxiosError) {
        if (!response.data) {
          initialCollapses();
          voltar();
          return;
        }
        nfSaidaEntity = new NfSaida(response.data, id);
        if (
          nfSaidaEntity?.status === "ENVIADA" ||
          nfSaidaEntity?.status === "CANCELADA" ||
          nfSaidaEntity?.status === "CONTINGENCIA"
        ) {
          await buscarNfSaidaDadosXml(id);
        }
      } else {
        responseErros(response);
      }
    } else {
      nfSaidaEntity = window?.nfSaidaEntity;
    }
    inserirDadosNfSaida().then(inserirDadosPessoas());
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(nfSaidaEntity);
    setUserLog(logs);
  }

  async function inserirDadosNfSaida() {
    await buscaDadosOperacoesFiscais();
    const operacaoFiscal = await getOperacaoFiscal(
      nfSaidaEntity?.operacaoFiscalId
    );
    nfSaidaEntity.setOperacaoFiscal(operacaoFiscal);
    nfSaidaEntity.setChangeItens(
      id,
      nfSaidaEntity?.nfSaidaItens,
      nfSaidaEntity
    );
    nfSaidaEntity.setChangePagamentos(nfSaidaEntity?.nfSaidaPagamentos);
    setNfSaida(nfSaidaEntity);
    setOperacaoFiscal(operacaoFiscal);
    setFrete(nfSaidaEntity?.frete);
    const nfSaidaItens = nfSaidaEntity?.nfSaidaItens?.length
      ? nfSaidaEntity?.nfSaidaItens.map((produto) => ({
          ...produto,
          id: shortid.generate(),
        }))
      : [];
    const nfSaidaPagamentos = nfSaidaEntity?.nfSaidaPagamentos?.length
      ? nfSaidaEntity?.nfSaidaPagamentos.map((pagamento) => ({
          ...pagamento,
          id: shortid.generate(),
        }))
      : [];
    setNfSaidaProdutos(nfSaidaItens);
    setNfSaidaPagamentos(nfSaidaPagamentos);
    setEnderecoAleterado(nfSaidaEntity.nfSaidaEnderecoCliente);
  }

  async function inserirDadosPessoas() {
    const pessoaIds = [];
    if (nfSaidaEntity?.clienteId) {
      pessoaIds.push(nfSaidaEntity?.clienteId);
    } else if (nfSaidaEntity?.clienteNome || nfSaidaEntity?.clienteCnpjCpf) {
      setClienteSelecionado({
        nomeRazaoSocial: nfSaidaEntity?.clienteNome ?? null,
        cnpjCpf: nfSaidaEntity?.clienteCnpjCpf ?? null,
      });
      setClienteSemCadastro(true);
    }
    if (nfSaidaEntity?.transportadoraId) {
      pessoaIds.push(nfSaidaEntity?.transportadoraId);
    } else if (
      nfSaidaEntity?.transportadoraNome ||
      nfSaidaEntity?.transportadoraCnpjCpf
    ) {
      setTransportadoraSelecionada({
        nomeRazaoSocial: nfSaidaEntity?.transportadoraNome ?? null,
        cnpjCpf: nfSaidaEntity?.transportadoraCnpjCpf ?? null,
      });
    }
    if (pessoaIds.length) {
      const pessoas = await buscarPessoas({ id: pessoaIds });
      if (pessoas.length) {
        if (nfSaidaEntity?.clienteId) {
          const cliente = pessoas.find(
            (pessoa) => pessoa.id == nfSaidaEntity?.clienteId
          );
          setClienteSelecionado(cliente);
          adicionarEnderecosCliente(cliente.enderecos);
        }
        if (nfSaidaEntity?.transportadoraId) {
          const transportadora = pessoas.find(
            (pessoa) => pessoa.id == nfSaidaEntity?.transportadoraId
          );
          setTransportadoraSelecionada(transportadora);
        }
      }
    }
  }

  async function buscarPessoas(filtros) {
    const res = await pessoaService.getAll(filtros);
    if (!res.isAxiosError) {
      return res.data;
    } else {
      responseErros(res);
    }
    return [];
  }

  const buscarPessoasPorTipo = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      nonPaginated: true,
      ativado: true,
    };
    const tipoPessoaTemp = tiposPessoa.find(
      ({ tipoId }) => tipoId === tiposPessoaId
    );
    const pessoas = await buscarPessoas(filtro);
    setLoadingAutoComplete(false);
    if (pessoas?.length) {
      tipoPessoaTemp?.setLista(pessoas);
    }
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 4,
      vendedor: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarTabelasPrecoCliente = async (tabelaPrecoId) => {
    if (!tabelaPrecoId) return;
    tabelaPrecoService.getById(tabelaPrecoId).then((result) => {
      if (!result.isAxiosError) {
        setTabelaPrecoCliente(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarOperacoesFiscais = async (filtros, idOperacaoFiscalVenda) => {
    operacaoFiscalService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data);
        if (idOperacaoFiscalVenda && result.data?.length) {
          const operacaoPadrao = result.data.find(
            (operacao) => operacao.id === idOperacaoFiscalVenda
          );
          if (operacaoPadrao) {
            setOperacaoFiscal(operacaoPadrao);
            nfSaidaEntity.setOperacaoFiscal(operacaoPadrao);
            setNfSaida(nfSaidaEntity);
          }
        }
      } else {
        responseErros(result);
      }
    });
  };

  const getOperacaoFiscal = async (id) => {
    if (!id) return;
    return operacaoFiscalService.getById(id).then((result) => {
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
        return null;
      }
    });
  };

  const buscarCondicoesPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    condicoesPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    if (
      nfSaidaEntity?.documentoDevolucaoCliente ||
      nfSaidaEntity?.documentoDevolucaoFornecedor
    ) {
      filtro.nfePagamento = "fpCreditoLoja";
    }
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
        setMeioPagamentoCredito(
          result.data.find((meio) => meio.nfePagamento == "fpCreditoLoja")
        );
      } else {
        responseErros(result);
      }
    });
  };

  const buscarProdutosSimilares = async (produtoId) => {
    if (produtoId) {
      produtosService.getById(produtoId).then((result) => {
        if (!result.isAxiosError) {
          setProdutosSimilares(result.data.similares);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarDadosClientes = async (clienteId) => {
    if (!clienteId) return setClienteSelecionado(null);
    pessoaService.getById(clienteId).then((result) => {
      if (!result.isAxiosError) {
        setClienteSelecionado(result.data);
        if (result.data.enderecos?.length)
          adicionarEnderecosCliente(result.data.enderecos);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarDadosTransportadora = async (transportadoraId) => {
    if (transportadoraId) {
      pessoaService.getById(transportadoraId).then((result) => {
        if (!result.isAxiosError) {
          setTransportadoraSelecionada(result.data);
        } else {
          responseErros(result);
        }
      });
    } else {
      setTransportadoraSelecionada(null);
    }
  };

  async function adicionarEnderecosCliente(enderecos) {
    const enderecoPadrao = encontrarEnderecoPadrao(enderecos);
    if (enderecoPadrao) {
      const enderecoCliente = mapearEndereco(enderecoPadrao);
      setEnderecoCliente(enderecoCliente);
      nfSaidaEntity.setEnderecoCliente(null, enderecoCliente);
    }
    const enderecosMapeados = mapearEnderecos(enderecos);
    setEnderecoClienteDataGrid(enderecosMapeados);
  }

  const buscaEnderecos = async (clienteId) => {
    if (!clienteId) return;
    const enderecos = await pessoaService
      .getEnderecos(clienteId)
      .then((result) => {
        if (result.isAxiosError) {
          responseErros(result);
          return [];
        }
        return result.data;
      });
    await adicionarEnderecosCliente(enderecos);
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    if (name === "acrescimo" || name === "despesas") {
      nfSaidaEntity.setHandleChangeInput(name, unformatPrice(e.target.value));
    } else {
      nfSaidaEntity.setHandleChangeInput(name, e.target.value);
    }
    setNfSaida(nfSaidaEntity);
  };

  const onSearchChangePessoas = (event, inputRef, tiposPessoaId) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      if (tiposPessoaId === 4) {
        filtros = { ...filtros, vendedor: "true" };
      }
      setLoadingAutoComplete(true);
      buscarPessoasPorTipo(filtros, tiposPessoaId);
    }
  };

  const handleDateChange = (fieldName, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    nfSaidaEntity.setHandleChangeDate(fieldName, date);
    setNfSaida(nfSaidaEntity);
  };

  const handleCloseCadastroCliente = () => {
    buscarPessoasPorTipo();
    nfSaidaEntity.clienteId = null;
    setNfSaida(nfSaidaEntity);
    setIsCadastroPessoaDialogOpen(false);
  };

  const handleDialogErrorDfe = (mensagem, status, idNfSaida) => {
    setErrosDfe({ mensagem, status, idNfSaida });
    setErrosDfeDialogOpen(true);
  };

  const verificarStatus = (status) => {
    switch (status) {
      case "PENDENTE":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#EA9D2B" }}></i>
        );
      case "ENVIADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#4FBB53" }}></i>
        );
      case "CONTINGENCIA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#BFBFBF" }}></i>
        );
      case "CANCELADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#DC3545" }}></i>
        );
      case "DENEGADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#70309F" }}></i>
        );
      case "EM PROCESSAMENTO":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#A0522D" }}></i>
        );
      case "FATURADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#004780" }}></i>
        );
    }
  };

  const salvarEndereco = async () => {
    const body = {
      ...endereco,
      cidadeId: cidadeId,
      pessoaId: nfSaida?.clienteId,
    };
    enderecoValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        const res = await pessoaService.cadastrarEndereco(
          nfSaida?.clienteId,
          body
        );
        if (!res.isAxiosError) {
          notification.cadastroSucesso();
          buscaEnderecos();
          setEnderecoClienteDataGrid([...enderecoClienteDataGrid, body]);
        } else {
          responseErros(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const inicialFormulario = () => {
    setNfSaida({ freteCompoeNota: false });
    setNfSaidaProdutos([]);
    setNfSaidaPagamentos([]);
  };

  const removerDadosCliente = () => {
    setEnderecoClienteDataGrid([]);
    setEnderecoCliente(null);
    setEnderecoAleterado(null);
    nfSaidaEntity.setEnderecoCliente();
  };

  const onChangeAutocomplete = (name, value) => {
    nfSaidaEntity.setHandleChangeAutoComplete(name, value);
    setNfSaida(nfSaidaEntity);
  };

  const onClickCancelarNfSaida = async () => {
    setOpenCancelamentoDialog(true);
  };

  const onClickValidarNfSaida = async () => {
    if (id) {
      setLoading(true);
      const response = await nfSaidaService.validarNotaFiscal(id);
      setLoading(false);
      if (!response.isAxiosError) {
        notification.sucessoGenericos("Nota validada com sucesso");
      } else {
        const res = response.response;
        if (res.status === 500 && res.data?.message) {
          handleDialogErrorDfe(res.data.message, 500);
          return;
        }
        if (res.status === 400 && res.data?.message) {
          handleDialogErrorDfe(res.data.message, 400);
          return;
        }
        notification.alertaGenericos(response.mensagem);
      }
    }
  };

  const onChangeAutocompleteCliente = (newValue) => {
    setInputErro([]);
    setClienteSemCadastro(false);
    removerDadosCliente();
    onChangeAutocomplete("clienteId", newValue);
    setClienteSelecionado(newValue);
    buscarNotasCredito(newValue?.id);
    buscarDadosClientes(newValue?.id);
    buscarTabelasPrecoCliente(newValue?.clientes[0]?.tabelaPrecoPadraoId);
  };

  const onChangeAutocompleteOperacaoFiscal = (newValue) => {
    setInputErro([]);
    nfSaidaEntity.setOperacaoFiscal(newValue);
    setOperacaoFiscal(newValue);
    setNfSaida(nfSaidaEntity);
  };

  const buscarNotasCredito = (pagadorId) => {
    const filtro = {
      pagadorId,
      tipo: "NOTA_CREDITO",
      status: ["ABERTO", "BAIXADO_PARCIAL"],
      restritiva: true,
    };
    contasReceberService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaNotasCredito(result.data.rows);
      } else responseErros(result);
    });
  };

  async function alertaNotasCredito() {
    const totalNf =
      (nfSaida.totalVenda || 0) - (nfSaida.totalPagamentos || 0) > 0;
    if (
      listaNotasCredito.length &&
      !nfSaidaEntity?.documentoDevolucaoCliente &&
      !nfSaidaEntity?.documentoDevolucaoFornecedor &&
      totalNf
    ) {
      const confirmacaoAlerta = await notification.confirmacaoGenericos({
        title: "Cliente com crédito",
        text: `Deseja utilizar nesta compra?`,
        icon: "warning",
        confirmButtonText: "SIM",
        cancelButtonText: "NÃO",
        showCancelButton: true,
      });
      if (confirmacaoAlerta.isConfirmed) {
        setOpenNotasCreditoDialog(true);
      }
    }
  }

  const buscarNfSaidaDadosXml = async (idCadastrado) => {
    setLoading(true);
    const result = await nfSaidaService.getDadosXml(idCadastrado);
    if (!result.isAxiosError) {
      setDadosImpressao(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCartaCorrecao = () => {
    setOpenCartaCorrecaoDialog(true);
  };

  const onClickOpenDialogCopiarNfSaida = () => {
    setOpenCopiarNfSaidaDialog(true);
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    nfSaidaValidatorService
      .validate(nfSaidaEntity, { abortEarly: false })
      .then(async () => {
        const isValid = validarNfSaida();
        if (!isValid) return;
        if (id) {
          const response = await nfSaidaService.atualizar(id, nfSaidaEntity);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            initialCollapses();
            voltar();
          } else {
            responseErros(response);
          }
        } else {
          const response = await nfSaidaService.cadastrar(nfSaidaEntity);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              const nf = { ...nfSaida, ...response.data };
              setNfSaida(nf);
              emitirAposCadastro(nf);
            } else {
              inicialFormulario();
            }
          } else responseErros(response);
        }
        window.nfSaidaEntity = {
          dataEmissao: formatDate.toSend(new Date()),
          nfSaidaItens: [],
          nfSaidaPagamentos: [],
          documentoImportado: false,
        };
      })
      .catch(async (err) => {
        setLoading(false);
        if (err.inner[0].path == "nfSaidaItens") setAbaValue(0);
        if (err.inner[0].path == "nfSaidaPagamentos") {
          await alertaNotasCredito();
          setAbaValue(1);
        }
        inputErros.set(err);
      });
  };

  const validarNfSaida = () => {
    if (
      operacaoFiscal?.modeloDocumento === "55" &&
      operacaoFiscal?.finalidade !== "DEVOLUCAO" &&
      !nfSaidaEntity?.nfSaidaEnderecoCliente?.id
    ) {
      notification.alertaGenericos(
        "É necessario cadastro de endereço para salvar"
      );
      setLoading(false);
      return false;
    }
    if (
      !verificaBaixaAutomatica(nfSaidaEntity, meioPagamentoList) &&
      !nfSaidaEntity.clienteId
    ) {
      notification.alertaGenericos(
        "É necessário informar cliente para vendas a prazo"
      );
      setLoading(false);
      return false;
    }
    const pagamentosTotalNfSaida =
      compararPagamentosTotalNfSaida(nfSaidaEntity);
    if (pagamentosTotalNfSaida) {
      notification.alertaGenericos(
        "É necessário atualizar valor da(s) parcela(s)"
      );
      setLoading(false);
      setAbaValue(1);
      return false;
    }
    if (
      Number(nfSaidaEntity?.totalVenda) > 500 &&
      !nfSaidaEntity?.clienteCnpjCpf &&
      operacaoFiscal?.modeloDocumento !== "01"
    ) {
      setLoading(false);
      setIdentificarClienteDialogOpen(true);
      notification.alertaGenericos(
        "Vendas de valor superior a R$ 500 devem identificar cliente."
      );
      return false;
    }
    return true;
  };

  const emitirAposCadastro = async (data) => {
    if (data.modeloDocumento == "01") {
      return await imprimeModeloZero(data);
    }
    const confirmacaoAlerta = await notification.confirmacaoGenericos({
      title: "Documento cadastrado com sucesso",
      text: `#${data.numeroDocumento}`,
      icon: "success",
      confirmButtonText: "EMITIR",
      cancelButtonText: "FECHAR",
      showCancelButton: true,
    });
    if (confirmacaoAlerta.isConfirmed) {
      const notaEmitida = await emitirNfSaida(data.id);
      if (notaEmitida && configuracoes?.visualizarImpressaoEmissao) {
        await buscarNfSaidaDadosXml(data.id);
      }
    } else {
      history.push(`/faturamento/nf-saida/${data.id}`);
    }
  };

  const imprimeModeloZero = async (data) => {
    if (!configuracoes?.visualizarImpressaoEmissao) {
      initialCollapses();
      voltar();
      return;
    }
    const confirmacaoAlerta = await notification.confirmacaoGenericos({
      title: "Documento cadastrado com sucesso",
      text: `#${data.numeroDocumento}`,
      icon: "success",
      confirmButtonText: "IMPRIMIR",
      cancelButtonText: "FECHAR",
      showCancelButton: true,
    });
    if (confirmacaoAlerta.isConfirmed) {
      history.push(`/faturamento/nf-saida/${data.id}`);
      setTimeout(() => {
        document.getElementById("botaoMenuAction")?.click();
      }, 2600);
    } else {
      initialCollapses();
      voltar();
    }
  };

  useEffect(() => {
    if (
      (dadosImpressao && !id) ||
      (dadosImpressao && nfSaida.status === "CONTINGENCIA")
    ) {
      history.push(`/faturamento/nf-saida/${nfSaida.id}`);
      setTimeout(() => {
        document.getElementById("impressoraAdd")?.click();
      }, 2555);
    }
  }, [dadosImpressao]);

  const emitirNfSaidaContigencia = async () => {
    setLoading(true);
    const response = await nfSaidaService.enviarNotaFiscalContigencia(id);
    setLoading(false);
    return alertaRetornoEmitirNota(response);
  };

  const emitirNfSaida = async (idNfSaida) => {
    setLoading(true);
    const response = await nfSaidaService.emitirNotaFiscal(idNfSaida);
    setLoading(false);
    return alertaRetornoEmitirNota(response, idNfSaida);
  };

  function alertaRetornoEmitirNota(response, idNfSaida = null) {
    if (!response.isAxiosError) {
      notification.sucessoGenericos("Nota Emitida com sucesso");
      return true;
    }
    const res = response.response;
    if (res.status === 500 && res.data?.message) {
      handleDialogErrorDfe(res.data.message, 500, idNfSaida);
    } else if (res.status === 400 && res.data?.message) {
      handleDialogErrorDfe(res.data.message, 400, idNfSaida);
    } else {
      notification.alertaGenericos(response.mensagem);
    }
    return false;
  }

  const onClickEmitirNfSaidaEdicao = async () => {
    const notaEmitida = await emitirNfSaida(id);
    if (notaEmitida) await buscarNfSaida();
  };

  const onClickEmitirNfSaidaContigenciaEdicao = async () => {
    const notaEmitida = await emitirNfSaidaContigencia();
    if (notaEmitida) await buscarNfSaida();
  };

  const onClickEmitirNfSaidaPendenteContingencia = async () => {
    const idNf = errosDfe.idNfSaida || id;
    if (idNf) {
      const notaEmitidaContingencia = await emitirNfSaidaPendenteContingencia(
        idNf
      );
      if (notaEmitidaContingencia) {
        if (id) return buscarNfSaida();
        history.push(`/faturamento/nf-saida/${idNf}`);
      }
    }
  };

  const emitirNfSaidaPendenteContingencia = async (idNfSaida) => {
    setLoading(true);
    const response = await nfSaidaService.emitirNotaFiscalPendenciaContingencia(
      idNfSaida
    );
    setLoading(false);
    return alertaRetornoEmitirPendenteContingenciaNota(response);
  };

  function alertaRetornoEmitirPendenteContingenciaNota(response) {
    if (!response.isAxiosError) return true;
    notification.alertaGenericos(response.mensagem);
    return false;
  }

  const onClickBaixarXml = async () => {
    setLoading(true);
    const result = await nfSaidaService.getArquivoXml(id);
    if (!result.isAxiosError) {
      const blob = new Blob([result.data], { type: "text/xml" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `${nfSaida.chaveAcesso}.xml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  async function onClickConsultarStatus() {
    setLoading(true);
    const result = await nfSaidaService.consultarStatusNotaFiscal(id);
    setLoading(false);
    if (!result.isAxiosError) {
      buscaDadosIniciais();
      notification.alertaGenericos("Status atualizado para Enviada");
    } else {
      alertaRetornoConsultarStatus(result);
    }
  }

  function alertaRetornoConsultarStatus(result) {
    const response = result.response;
    if (response.status == 500) {
      notification.alertaGenericos(response.data.message);
    }
  }

  return (
    <AddLayout
      id={id}
      disableAtualizar={
        (id && nfSaida?.status !== "PENDENTE") ||
        !permissoesHelper.temPermisao("nf-saida-editar")
      }
      title={id ? "Visualizar Nf-Saida" : "Cadastrar Nf-Saida"}
      onClickSalvar={handleSubmit}
      loading={loading}
      codigo={
        id && nfSaida?.numeroDocumento
          ? `Nº Documento ${nfSaida?.numeroDocumento}`
          : ""
      }
      userLog={id ? userLog : null}
      onClickLog={onClickLog}
      ButtonMenu={
        <ButtonMenu
          id={id}
          nfSaida={{
            ...nfSaidaEntity,
            empresa: {
              email: dadosEmpresa.empresa?.email,
              cnpjCpf: dadosEmpresa.empresa?.cnpjCpf,
              uf: dadosEmpresa.empresa?.enderecoUF,
              nome:
                dadosEmpresa.empresa?.fantasia ??
                dadosEmpresa.empresa?.razaoSocial,
              telefone:
                dadosEmpresa.empresa?.celular ?? dadosEmpresa.empresa?.telefone,
              emiteNfe: dadosEmpresa.empresa?.emiteNfe,
              emiteNfce: dadosEmpresa.empresa?.emiteNfce,
            },
            vendedor: value.autoComplete(vendedorList, nfSaida?.vendedorId),
            cliente: clienteSelecionado,
            enderecoCliente: enderecoAleterado || enderecoCliente,
            estadoCliente: enderecoCliente?.estado,
            pagamentos: nfSaidaPagamentos,
            meioPagamentoList,
          }}
          operacaoFiscal={operacaoFiscal}
          dadosImpressao={dadosImpressao}
          onClickCancelarNfSaida={onClickCancelarNfSaida}
          onClickEmitirNfSaida={onClickEmitirNfSaidaEdicao}
          onClickEmitirNfSaidaContigenciaEdicao={
            onClickEmitirNfSaidaContigenciaEdicao
          }
          onClickValidarNfSaida={onClickValidarNfSaida}
          onClickCartaCorrecao={onClickCartaCorrecao}
          onClickConsultarStatus={onClickConsultarStatus}
          onClickBaixarXml={onClickBaixarXml}
          permissaoCancelar={permissoesHelper.temPermisao("nf-saida-cancelar")}
        />
      }
      actions={
        (!id && !documentoImportado) || nfSaidaEntity.operacaoEntrada ? (
          <Tooltip title="Copiar Nf-Saida" arrow placement="left">
            <IconButton onClick={() => onClickOpenDialogCopiarNfSaida()}>
              <i
                className="ph-fill ph-copy-simple"
                style={{ fontSize: 18 }}
              ></i>
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )
      }
      actionsTotalizadores={
        <ResumoTotal
          nfSaidaId={id}
          nfSaida={nfSaida}
          nfSaidaEntity={nfSaidaEntity}
          setNfSaida={setNfSaida}
          totalItensLista={totalItensLista}
          nfSaidaProdutos={nfSaidaProdutos}
          setNfSaidaProdutos={setNfSaidaProdutos}
          abaValue={abaValue}
          setNfSaidaPagamentos={setNfSaidaPagamentos}
          permissaoEditarDesconto={permissoesHelper.temPermisao(
            "nf-saida-editar-desconto"
          )}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <DatePicker
            id="dataEmissao"
            name="dataEmissao"
            label="Data Emissão"
            format="dd/MM/yyyy"
            variant="outlined"
            disabled={id}
            required
            value={nfSaida?.dataEmissao ? nfSaida.dataEmissao : null}
            onChange={(date) => {
              setInputErro([]);
              handleDateChange("dataEmissao", date);
            }}
            error={inputErros.get("dataEmissao")}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            required
            id="operacaoFiscalId"
            name="operacaoFiscalId"
            options={operacaoFiscalList}
            noOptionsText="Sem opções"
            autoHighlight
            disableClearable
            getOptionLabel={(option) =>
              option.descricao ? option.descricao : ""
            }
            disabled={id ? true : false}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Operação Fiscal"
                variant={nfSaida.status === "CANCELADA" ? "filled" : "outlined"}
                disabled={id ? true : false}
                error={inputErros.get("operacaoFiscalId")}
                required
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocompleteOperacaoFiscal(newValue);
            }}
            value={
              operacaoFiscal
                ? operacaoFiscal
                : value.autoComplete(
                    operacaoFiscalList,
                    nfSaida.operacaoFiscalId
                  )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="numeroDocumento"
            name="numeroDocumento"
            type="number"
            label="Nº Documento"
            variant="outlined"
            disabled
            value={nfSaida.numeroDocumento ?? ""}
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="serieDocumento"
            name="serieDocumento"
            label="Série Documento"
            variant="outlined"
            disabled
            value={nfSaida.serieDocumento ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="modeloDocumento"
            name="modeloDocumento"
            label="Modelo Documento"
            variant="outlined"
            disabled
            value={nfSaida.modeloDocumento ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={6}>
          <Autocomplete
            id="clienteId"
            name="clienteId"
            disabled={
              (id && nfSaida?.status !== "PENDENTE") || abaValue !== 0
                ? true
                : false
            }
            options={clienteList}
            autoHighlight
            getOptionLabel={(option) => {
              let codigoPessoa = option?.codigo ? `${option.codigo} - ` : "";
              let descricaoPessoa = codigoPessoa + `${option.nomeRazaoSocial}`;
              return option ? descricaoPessoa : "";
            }}
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText={
              document.getElementById("clienteId")?.value.length >= 1 && !id ? (
                <Grid container spacing={1}>
                  <Grid item xs={7} className="mt-2" fullWidth>
                    Nenhum cliente encontrado
                  </Grid>
                  <Grid item xs={5} className="d-flex justify-content-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsCadastroPessoaDialogOpen(true)}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                "Digite e pressione Enter"
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  !nfSaidaEntity?.documentoDevolucaoFornecedor
                    ? "Cliente"
                    : "Fornecedor"
                }
                error={inputErros.get("clienteId")}
                required={
                  operacaoFiscal?.modeloDocumento === "55" ? true : false
                }
                variant={"outlined"}
                onKeyDown={(e) =>
                  onSearchChangePessoas(
                    e,
                    inputCliente,
                    !nfSaidaEntity?.documentoDevolucaoFornecedor ? 1 : 2
                  )
                }
                inputRef={inputCliente}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {operacaoFiscal?.modeloDocumento === "65" && !id && (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={id ? true : false}
                            onClick={() =>
                              setIdentificarClienteDialogOpen(true)
                            }
                          >
                            <i
                              className="ph ph-user-plus"
                              style={{ fontSize: 24 }}
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      )}
                      <React.Fragment>
                        {loadingAutoComplete ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    </>
                  ),
                }}
              />
            )}
            onChange={(_, newValue) => onChangeAutocompleteCliente(newValue)}
            value={clienteSelecionado}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="vendedorId"
            name="vendedorId"
            disabled={
              id ||
              !nfSaida?.operacaoFiscalId ||
              colaboradorVendedor ||
              nfSaidaEntity?.documentoDevolucaoCliente
            }
            options={vendedorList}
            autoHighlight
            getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendedor"
                variant={"outlined"}
                required={permissoesHelper.temPermisao(
                  "nf-saida-vendedor-obrigatorio"
                )}
                error={inputErros.get("vendedorId")}
              />
            )}
            onChange={(_, newValue) => {
              setInputErro([]);
              onChangeAutocomplete("vendedorId", newValue);
            }}
            value={value.autoComplete(vendedorList, nfSaida?.vendedorId)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="status"
            name="status"
            label="Status"
            variant="outlined"
            disabled
            value={nfSaida.status ?? ""}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {verificarStatus(nfSaida.status)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {(nfSaida.status === "ENVIADA" || nfSaida.status === "CANCELADA") && (
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6}>
            <TextField
              id="chaveAcesso"
              name="chaveAcesso"
              label="Chave de Acesso"
              variant="outlined"
              disabled
              value={nfSaida.chaveAcesso ?? ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="numeroProtoc"
              name="numeroProtoc"
              label="Número Protocolo"
              variant="outlined"
              disabled
              value={nfSaida.numeroProtoc ?? ""}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <hr className="mt-4 mb-2" />
      <Paper>
        <Tabs
          value={abaValue}
          onChange={async (e, value) => {
            e.persist();
            if (value === 1) {
              await alertaNotasCredito();
            }
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="ITENS DA NOTA" {...tabProps(0)} />
          <Tab
            label="PAGAMENTOS"
            {...tabProps(1)}
            disabled={nfSaida.chaveAcessoReferencia != null}
          />
          <Tab
            disabled={!clienteSelecionado}
            label="DADOS DO CLIENTE"
            {...tabProps(2)}
          />
          <Tab label="TRIBUTOS" {...tabProps(3)} />
          <Tab label="TRANSPORTE" {...tabProps(4)} />
          <Tab label="INFORMAÇÕES ADICIONAIS" {...tabProps(5)} />
        </Tabs>
      </Paper>
      <CardContent>
        <TabPanel value={abaValue} index={0}>
          {abaValue === 0 ? (
            <NfSaidaItens
              nfSaidaId={id}
              nfSaida={nfSaida}
              setNfSaida={setNfSaida}
              nfSaidaEntity={nfSaidaEntity}
              nfSaidaProdutos={nfSaidaProdutos}
              tabelaPrecoCliente={tabelaPrecoCliente}
              produtosSimilares={produtosSimilares}
              buscarProdutosSimilares={buscarProdutosSimilares}
              setNfSaidaProdutos={setNfSaidaProdutos}
              setNfSaidaPagamentos={setNfSaidaPagamentos}
              setLoading={setLoading}
              permitirVendaEstoqueNegativo={configuracoes?.vendaEstoqueNegativo}
              situacaoIpiList={situacaoIpiList}
              origensMercadoriasList={origensMercadoriasList}
              operacaoFiscal={operacaoFiscal}
              unidadeList={unidadeList}
              setUnidadeList={setUnidadeList}
              gruposTributacaoList={gruposTributacaoList}
              setGruposTributacaoList={setGruposTributacaoList}
              csoSimplesList={csoSimplesList}
              setCsoSimplesList={setCsoSimplesList}
              enquadramentoLegalList={enquadramentoLegalList}
              setEnquadramentoLegalList={setEnquadramentoLegalList}
              permissaoEditarPrecoVenda={permissoesHelper.temPermisao(
                "nf-saida-preco-venda"
              )}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={1}>
          {abaValue === 1 ? (
            <NfSaidaPagamentos
              nfSaidaId={id}
              nfSaida={nfSaida}
              setNfSaida={setNfSaida}
              nfSaidaEntity={nfSaidaEntity}
              nfSaidaPagamentos={nfSaidaPagamentos}
              setNfSaidaPagamentos={setNfSaidaPagamentos}
              meioPagamentoList={meioPagamentoList}
              condicaoPagamentoList={condicaoPagamentoList}
              onChangeAutocomplete={onChangeAutocomplete}
              handleInputChange={handleInputChange}
              meioPagamentoSelecionado={meioPagamentoSelecionado}
              setMeioPagamentoSelecionado={setMeioPagamentoSelecionado}
              listaNotasCredito={listaNotasCredito}
              setListaNotasCredito={setListaNotasCredito}
              setOpenNotasCreditoDialog={setOpenNotasCreditoDialog}
              openNotasCreditoDialog={openNotasCreditoDialog}
              notasCredito={notasCredito}
              setNotasCredito={setNotasCredito}
              meioPagamentoCredito={meioPagamentoCredito}
              buscarNotasCredito={buscarNotasCredito}
              setAbaValue={setAbaValue}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={2}>
          {clienteSelecionado || abaValue === 2 ? (
            <NfSaidaDadosClientes
              nfSaidaId={id}
              nfSaida={nfSaida}
              enderecoAleterado={enderecoAleterado}
              setEnderecoAleterado={setEnderecoAleterado}
              enderecoCliente={enderecoCliente}
              enderecoClienteDataGrid={enderecoClienteDataGrid}
              clienteSelecionado={clienteSelecionado}
              cidadeId={cidadeId}
              setCidadeId={setCidadeId}
              endereco={endereco}
              setEndereco={setEndereco}
              salvarEndereco={salvarEndereco}
              clienteSemCadastro={clienteSemCadastro}
              inputErros={inputErros}
              nfSaidaEntity={nfSaidaEntity}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={3}>
          {abaValue === 3 ? (
            <NfSaidaTributos
              nfSaida={nfSaida}
              setNfSaida={setNfSaida}
              nfSaidaEntity={nfSaidaEntity}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={4}>
          <NfSaidaTransporte
            id={id}
            nfSaida={nfSaida}
            transportadoraList={transportadoraList}
            transportadoraSelecionada={transportadoraSelecionada}
            setTransportadoraSelecionada={setTransportadoraSelecionada}
            frete={frete}
            unformatPrice={unformatPrice}
            setFrete={setFrete}
            inputErros={inputErros}
            setInputErro={setInputErro}
            handleInputChange={handleInputChange}
            onChangeAutocomplete={onChangeAutocomplete}
            onSearchChangePessoas={onSearchChangePessoas}
            buscarDadosTransportadora={buscarDadosTransportadora}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={5}>
          {abaValue === 5 ? (
            <NfSaidaInformacoes
              nfSaidaId={id}
              nfSaida={nfSaida}
              nfSaidaEntity={nfSaidaEntity}
              handleInputChange={handleInputChange}
            />
          ) : null}
        </TabPanel>
        <FullscreenDialog
          open={isCadastroPessoaDialogOpen}
          handleClose={handleCloseCadastroCliente}
          content={
            <AddPessoas
              match={{ params: { id: null } }}
              isFullscreenDialog
              handleCloseFullscreenDialog={handleCloseCadastroCliente}
            />
          }
        />
      </CardContent>
      <CancelamentoDialog
        open={openCancelamentoDialog}
        setOpen={setOpenCancelamentoDialog}
        setLoading={setLoading}
        id={id}
        operacaoFiscal={operacaoFiscal}
        nfSaida={nfSaida}
      />
      <ErrosDfeDialog
        open={errosDfeDialogOpen}
        setOpen={setErrosDfeDialogOpen}
        errosDfe={errosDfe}
        handleInputChange={handleInputChange}
        onClickEmitirNfSaidaPendenteContingencia={
          onClickEmitirNfSaidaPendenteContingencia
        }
      />
      <IdentificarClienteDialog
        open={identificarClienteDialogOpen}
        setOpen={setIdentificarClienteDialogOpen}
        setClienteSelecionado={setClienteSelecionado}
        nfSaidaEntity={nfSaidaEntity}
        setNfSaida={setNfSaida}
        setClienteSemCadastro={setClienteSemCadastro}
      />
      <CartaCorrecaoDialog
        openCartaCorrecaoDialog={openCartaCorrecaoDialog}
        setOpenCartaCorrecaoDialog={setOpenCartaCorrecaoDialog}
        id={id}
        setLoading={setLoading}
        nfSaida={nfSaida}
        clienteSelecionado={clienteSelecionado}
        enderecoCliente={enderecoCliente}
      />
      <CopiarNfSaidaDialog
        open={openCopiarNfSaidaDialog}
        setOpen={setOpenCopiarNfSaidaDialog}
        nfSaidaEntity={nfSaidaEntity}
        setNfSaida={setNfSaida}
        setNfSaidaProdutos={setNfSaidaProdutos}
      />
    </AddLayout>
  );
};

export default AddNfSaida;
