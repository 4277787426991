import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  CardContent,
  DatePicker,
  FullscreenDialog,
  Grid,
  Paper,
  Tabs,
  Tab,
  TabPanel,
  TextField,
  FormControl,
  CircularProgress,
  Button,
} from "../../../components";
import { useTheme } from "@mui/material/styles";
import { AddLayout } from "../../../layouts";
import {
  notification,
  OrcamentosService,
  OrcamentosItensService,
  PessoaService,
  CondicoesPagamentoService,
  MeioPagamentoService,
  ProdutosService,
  TabelaPrecoService,
  OperacaoFiscalService,
  history,
  EnderecoService,
  SessionService,
  ColaboradorService,
  OrigemMercadoriaService,
} from "../../../services";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import {
  responseErros,
  formatDate,
  timeoutBuscaAutocomplete,
  userLogs,
  voltar,
} from "../../../utils";
import { enderecoValidator, orcamentosValidator } from "./middlewares";
import {
  ButtonMenu,
  OrcamentosItens,
  OrcamentosCampos,
  OrcamentosDadosClientes,
  ComissaoVendedorDialog,
  ResumoTotal,
} from "./components";
import {
  tabProps,
  calcularValorTotal,
  totalItensLista,
  totalOrcamento,
  encontrarEnderecoPadrao,
  mapearEnderecos,
  mapearEndereco,
} from "./addOrcamentos";
import AddPessoas from "../../pessoas/addPessoa/addPessoa.view";
import { useImportContext } from "../../../contexts/import.context";
import { Orcamento } from "./entities";
import { format } from "date-fns";

const AddOrcamentosView = ({ match }) => {
  const theme = useTheme();
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const inputCliente = useRef();
  const [loading, setLoading] = useState(true);
  const [userLog, setUserLog] = useState({});
  const [isCadastroPessoaDialogOpen, setIsCadastroPessoaDialogOpen] =
    useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [initialOrcamentosState, setInitialOrcamentosState] = useState({
    dataEmissao: formatDate.toSend(new Date()),
  });
  const [orcamento, setOrcamento] = useState({
    dataEmissao: formatDate.toSend(new Date()),
  });
  const prevStateRef = useRef(orcamento);
  const [contadorModificacao, setContadorModificacao] = useState(0);
  const [estados, setEstados] = useState([]);
  const [itemValorComissaoVendedor, setItemValorComissaoVendedor] =
    useState(null);
  const [itemPercentualComissaoVendedor, setItemPercentualComissaoVendedor] =
    useState(null);
  const [itemTotalComissaoVendedor, setItemTotalComissaoVendedor] =
    useState(null);
  const [listaParcelasGeradas, setListaParcelasGeradas] = useState([]);
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [produtoList, setProdutoList] = useState([]);
  const [produtosSimilares, setProdutosSimilares] = useState([]);
  const [cidadeId, setCidadeId] = useState(null);
  const [endereco, setEndereco] = useState({
    padrao: false,
  });
  const [enderecoCliente, setEnderecoCliente] = useState(null);
  const [enderecoClienteDataGrid, setEnderecoClienteDataGrid] = useState([]);
  const [enderecoAleterado, setEnderecoAleterado] = useState(null);
  const [pagamentoSelecionado, setPagamentoSelecionado] = useState(null);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [tabelaPrecoCliente, setTabelaPrecoCliente] = useState(null);
  const [listaOrcamentosProdutos, setListaOcamentosProdutos] = useState([]);
  const [origensMercadoriasList, setOrigensMercadoriasList] = useState([]);
  const [listaUnidadesComerciais, setListaUnidadesComerciais] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("orcamentos-visualizar");
  //colocar todos os server fora do component
  const sessionService = new SessionService();
  const colaboradorService = new ColaboradorService();
  const orcamentosService = new OrcamentosService();
  const orcamentosItensService = new OrcamentosItensService();
  const pessoaService = new PessoaService();
  const enderecoService = new EnderecoService();
  const condicaoPagamentoService = new CondicoesPagamentoService();
  const meioPagamentoService = new MeioPagamentoService();
  const produtosService = new ProdutosService();
  const tabelaPrecoService = new TabelaPrecoService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const dadosEmpresaUsuario = sessionService.getEmpresaAndUsuario();
  const containerRef = useRef(null);
  const tiposPessoa = [
    {
      tipoId: 1,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 4,
      setLista: (dados) => setVendedorList(dados),
    },
  ];

  //deve esta no local storage da empresa
  const buscarConfiguracao = async () => {
    if (dadosEmpresaUsuario.empresa.configuracoes) {
      const data = dadosEmpresaUsuario.empresa.configuracoes;
      if (data.operacaoFiscalVendasPadraoId) {
        buscarOperacaoFiscal(data.operacaoFiscalVendasPadraoId);
        return true;
      }
    }
  };

  async function buscaDadosIniciais() {
    buscarPessoaVendedor(); //esta duplicada
    await Promise.all([buscaOrcamentos(), buscaEstados()]);
    buscarVendedores(); //esta duplicada
    buscarCondicoesPagamento();
    buscarMeiosPagamento();
    buscarOrigensMercadoria();
    const operacaoPadrao = await buscarConfiguracao();
    if (!operacaoPadrao) buscarOperacaoFiscal();
  }

  useEffect(() => {
    buscaDadosIniciais().then(() => {
      setLoading(false);
    });
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  const buscarPessoaVendedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) =>
          colaborador.usuarioId === dadosEmpresaUsuario.usuario.id
      );
      if (colaboradorVendedorUsuario) {
        setOrcamento({
          ...orcamento,
          pessoaVendedorId: colaboradorVendedorUsuario.pessoa.id,
        });
      }
    } else {
      responseErros(result);
    }
  };

  const buscaOrcamentos = async () => {
    if (!id) return;
    const response = await orcamentosService.getById(id);
    if (!response.isAxiosError) {
      if (!response.data) {
        initialCollapses();
        voltar();
        return;
      }
      setInitialOrcamentosState(response.data);
      setOrcamento(response.data);
      setEnderecoAleterado(response.data.orcamentoEnderecoCliente);
      setListaParcelasGeradas(response.data?.pagamentos);
      buscarProdutosAvancado(response.data?.itens);
      buscarDadosClientes(response.data?.pessoaClienteId);
      buscaEnderecos(response.data?.pessoaClienteId);
    } else {
      responseErros(response);
      initialCollapses();
      voltar();
    }
  };

  //fazer funcao para chamando quando alterar
  useEffect(() => {
    if (!orcamento.pessoaClienteId) return;
    buscarDadosClientes(orcamento.pessoaClienteId);
    buscaEnderecos(orcamento.pessoaClienteId);
  }, [abaValue, orcamento?.pessoaClienteId]);

  //fazer funcao para chamando quando alterar
  useEffect(() => {
    if (pagamentoSelecionado) {
      filtrarCondicoesPagamento(pagamentoSelecionado);
    }
  }, [pagamentoSelecionado]);

  //fazer funcao para chamando quando alterar
  useEffect(() => {
    if (!prevStateRef?.current || contadorModificacao <= (id ? 4 : 2)) {
      setContadorModificacao((prevCount) => prevCount + 1);
      prevStateRef.current = montarObjetoOrcamento();
    }
  }, [orcamento]);

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(orcamento);
    setUserLog(logs);
  }

  const buscarPessoas = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      limite: 20,
      ativado: true,
    };
    const tipoPessoaTemp = tiposPessoa.find(
      ({ tipoId }) => tipoId === tiposPessoaId
    );
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoComplete(false);
      if (!res.isAxiosError) {
        tipoPessoaTemp?.setLista(res.data.rows);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 4,
      vendedor: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  //deve vir junto com cliente
  const buscaEstados = async () => {
    enderecoService.getEstados().then((res) => {
      if (!res.isAxiosError) {
        setEstados(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarOperacaoFiscal = (id) => {
    const filtros = {
      ativado: true,
      tipoOperacao: "SAIDA",
      restritiva: true,
      finalidade: ["NAO_SE_APLICA", "NFE_NORMAL"],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data.rows);
        if (id && result.data.rows?.length) {
          const operacaoPadrao = result.data.rows.find(
            (operacao) => operacao.id === id
          );
          if (operacaoPadrao) {
            setOrcamento((prevState) => ({
              ...prevState,
              operacaoFiscalId: operacaoPadrao.id,
              operacaoFiscal: operacaoPadrao,
              serieDocumento: operacaoPadrao?.serieDocumento,
              modeloDocumento: operacaoPadrao?.modeloDocumento,
            }));
          }
        }
      } else {
        responseErros(result);
      }
    });
  };

  const filtrarCondicoesPagamento = (pagamentoSelecionado) => {
    const condicaoPagamentoFiltrado = [];
    condicaoPagamentoList.forEach((condicao) =>
      condicao.meiosPagamentos.filter((meio) => {
        if (meio.id === pagamentoSelecionado?.id) {
          condicaoPagamentoFiltrado.push(condicao);
        }
      })
    );
    if (condicaoPagamentoFiltrado.length > 0) {
      return condicaoPagamentoFiltrado;
    } else {
      return [];
    }
  };

  const buscarDadosClientes = async (clienteId) => {
    if (clienteId) {
      pessoaService.getById(clienteId).then((result) => {
        if (!result.isAxiosError) {
          setClienteSelecionado(result.data);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriasList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarTabelasPrecoCliente = async (tabelaPrecoId) => {
    if (!tabelaPrecoId) return;
    tabelaPrecoService.getById(tabelaPrecoId).then((result) => {
      if (!result.isAxiosError) {
        setTabelaPrecoCliente(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  //deve vir junto com cliente
  const buscaEnderecos = async (clienteId) => {
    if (!clienteId) return;
    const enderecos = await pessoaService
      .getEnderecos(clienteId)
      .then((result) => {
        if (result.isAxiosError) {
          responseErros(result);
          return [];
        }
        return result.data;
      });
    const enderecoPadrao = encontrarEnderecoPadrao(enderecos);
    if (enderecoPadrao) {
      const enderecoCliente = await mapearEndereco(estados, enderecoPadrao);
      setEnderecoCliente(enderecoCliente);
    }
    const enderecosMapeados = await mapearEnderecos(estados, enderecos);
    setEnderecoClienteDataGrid(enderecosMapeados);
  };

  const buscarCondicoesPagamento = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      nonPaginated: true,
    };
    condicaoPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      nonPaginated: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarProdutosAvancado = (itens) => {
    const produtosIds = itens.map(({ produtoId }) => produtoId);
    const filtro = {
      id: produtosIds,
      ativado: true,
      nonPaginated: true,
    };
    produtosService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setProdutoList(result.data);
        if (itens && itens.length) {
          preencherItens(itens, result.data);
        }
      } else {
        responseErros(result);
      }
    });
  };

  const preencherItens = (itensBusca, produtos) => {
    setListaOcamentosProdutos(() => {
      const itens = itensBusca.map((item) => {
        const produtoBusca = produtos.find(
          (produto) => produto.id == item.produtoId
        );
        return {
          ...item,
          descricao: produtoBusca?.descricao,
          totalItem: calcularValorTotal(item),
        };
      });
      return itens;
    });
  };

  const buscarProdutosSimilares = async (produtoId) => {
    if (produtoId) {
      produtosService.getById(produtoId).then((result) => {
        if (!result.isAxiosError) {
          setProdutosSimilares(result.data.similares);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    const orcamentoTemp = {
      ...orcamento,
      [name]: value ? value.id : null,
    };
    if (name === "meioPagamentoId") {
      setPagamentoSelecionado(value);
      orcamentoTemp.condicaoPagamentoId = null;
    }
    setOrcamento(orcamentoTemp);
  };

  const handleChangeInput = (event) => {
    const pessoasTemp = {
      ...orcamento,
      [event.target.name]: event.target.value || undefined,
    };
    setOrcamento(pessoasTemp);
  };

  const onSearchChangePessoas = (event, tiposPessoaId) => {
    if (
      event.key === "Enter" &&
      inputCliente.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      if (tiposPessoaId === 4) {
        filtros = { ...filtros, vendedor: "true" };
      }
      setLoadingAutoComplete(true);
      buscarPessoas(filtros, tiposPessoaId);
    }
  };

  const handleCloseCadastroCliente = () => {
    buscarPessoas();
    setOrcamento((prevState) => ({
      ...prevState,
      pessoaClienteId: null,
    }));
    setIsCadastroPessoaDialogOpen(false);
  };

  const buscarProdutosCadastrados = () => {
    const filter = {
      nonPaginated: true,
      orcamentoId: id,
    };
    orcamentosItensService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        preencherItens(res.data, produtoList);
      } else {
        responseErros(res);
      }
    });
  };

  const removerProdutoCadastrado = async (idProduto) => {
    setLoading(true);
    const res = await orcamentosItensService.deletar(idProduto);
    if (!res.isAxiosError) {
      buscarProdutosCadastrados();
      notification.deletadoSucesso();
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const salvarEndereco = async () => {
    const body = {
      ...endereco,
      cidadeId: cidadeId,
      pessoaId: orcamento?.pessoaClienteId,
    };
    enderecoValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        const res = await pessoaService.cadastrarEndereco(
          orcamento?.pessoaClienteId,
          body
        );
        if (!res.isAxiosError) {
          notification.cadastroSucesso();
          buscaEnderecos();
          setEnderecoClienteDataGrid([...enderecoClienteDataGrid, body]);
        } else {
          responseErros(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const verificaVendaPrazo = () => {
    const pagamentoOrcamento = id
      ? meioPagamentoList.find(({ id }) => id === orcamento?.meioPagamentoId)
      : pagamentoSelecionado;
    if (!pagamentoOrcamento?.baixaAutomatica && !orcamento.pessoaClienteId) {
      notification.alertaGenericos(
        "É necessário informar cliente para vendas a prazo"
      );
      setLoading(false);
      return true;
    }
    return false;
  };

  //deve ser feita na entidade
  const montarObjetoOrcamento = () => {
    const orcamentoEnderecoCliente = enderecoAleterado
      ? {
          ...enderecoAleterado,
          pessoaId: orcamento?.pessoaClienteId,
        }
      : {
          ...enderecoCliente,
          pessoaId: orcamento?.pessoaClienteId,
        };
    return new Orcamento({
      ...orcamento,
      orcamentoItens: listaOrcamentosProdutos,
      orcamentoEnderecoCliente,
    });
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    const orcamentoDto = montarObjetoOrcamento(); //remover
    orcamentosValidator
      .validate(orcamentoDto, { abortEarly: false })
      .then(async () => {
        const verificarVendaPrazo = verificaVendaPrazo();
        if (verificarVendaPrazo) return;
        if (id) {
          const response = await orcamentosService.atualizar(id, orcamentoDto);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            initialCollapses();
            voltar();
          } else {
            buscaOrcamentos();
            responseErros(response);
          }
        } else {
          const response = await orcamentosService.cadastrar(orcamentoDto);
          setLoading(false);
          if (!response.isAxiosError) {
            setOrcamento({ ...orcamento, ...response.data });
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              const confirmacaoAlerta = await notification.confirmacaoGenericos(
                {
                  title: "Documento cadastrado com sucesso",
                  text: `#${response.data.numeroDocumento}`,
                  icon: "success",
                  confirmButtonText: "IMPRIMIR",
                  cancelButtonText: "FECHAR",
                  dangerMode: true,
                  showCancelButton: true,
                }
              );
              if (confirmacaoAlerta.isConfirmed) {
                document.getElementById("botaoMenuAction")?.click();
              } else {
                initialCollapses();
                voltar();
              }
            } else {
              handleCancelar();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setOrcamento(initialOrcamentosState);
    setListaOcamentosProdutos([]);
  };

  const onClickCancelarOrcamento = async () => {
    const retornoAlerta = await notification.confirmacao(
      "Cancelar!",
      "Tem certeza que deseja cancelar esse orçamento?"
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const response = await orcamentosService.cancelar(id);
      setLoading(false);
      if (!response.isAxiosError) {
        notification.alteracaoSucesso();
        history.push("/faturamento/orcamentos");
      } else {
        responseErros(response);
      }
    }
  };

  return (
    <AddLayout
      id={id}
      disableAtualizar={
        orcamento.status !== "ABERTO" ||
        !permissoesHelper.temPermisao("orcamentos-editar")
      }
      title={orcamento?.id ? "Editar orçamentos" : "Cadastrar orçamentos"}
      onClickSalvar={handleSubmit}
      loading={loading}
      codigo={
        id && orcamento?.numeroDocumento
          ? `Nº Documento ${orcamento?.numeroDocumento}`
          : ""
      }
      userLog={id ? userLog : null}
      onClickLog={onClickLog}
      ButtonMenu={
        <ButtonMenu
          id={id}
          data={{
            ...orcamento,
            ...empresa,
            itens: listaOrcamentosProdutos,
            pagamentos: listaParcelasGeradas,
            dataEmissaoFormat: format(
              new Date(Date.parse(orcamento.dataEmissao)),
              "dd/MM/yyyy HH:MM"
            ),
            vendedor: value.autoComplete(
              vendedorList,
              orcamento?.pessoaVendedorId
            )?.nomeRazaoSocial,
            cliente: clienteSelecionado,
            enderecoCliente: enderecoAleterado || enderecoCliente,
            estadoCliente: enderecoCliente?.estado,
            totalItens: totalItensLista(listaOrcamentosProdutos),
            totalOrcamento: totalOrcamento(orcamento, listaOrcamentosProdutos),
            meioPagamentoList,
          }}
          onClickCancelarOrcamento={onClickCancelarOrcamento}
        />
      }
      actionsTotalizadores={
        <ResumoTotal
          abaValue={abaValue}
          orcamento={orcamento}
          setOrcamento={setOrcamento}
          listaOrcamentosProdutos={listaOrcamentosProdutos}
        />
      }
      formState={{
        inicial: prevStateRef?.current,
        final: montarObjetoOrcamento(),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="serieDocumento"
              name="serieDocumento"
              label="Série"
              variant="filled"
              disabled
              value={orcamento.serieDocumento ?? ""}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            id="dataEmissao"
            name="dataEmissao"
            label="Data de Emissão"
            format="dd/MM/yyyy"
            variant="filled"
            disabled={true}
            value={value.date(orcamento?.dataEmissao)}
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="operacaoFiscalId"
              name="operacaoFiscalId"
              required
              disabled={id && orcamento.status !== "ABERTO"}
              options={operacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao ? `${option.descricao}` : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operação Fiscal"
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                  required
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setInputErro([]);
                handleChangeAutocomplete("operacaoFiscalId", newValue);
                setOrcamento((prevState) => ({
                  ...prevState,
                  operacaoFiscal: newValue,
                  serieDocumento: newValue?.serieDocumento,
                  modeloDocumento: newValue?.modeloDocumento,
                }));
              }}
              value={value.autoComplete(
                operacaoFiscalList,
                orcamento?.operacaoFiscalId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="pessoaClienteId"
              name="pessoaClienteId"
              disabled={id && orcamento.status !== "ABERTO"}
              options={clienteList}
              autoHighlight
              getOptionLabel={(option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.nomeRazaoSocial}`;
                return option ? descricaoProduto : "";
              }}
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText={
                document.getElementById("pessoaClienteId")?.value.length >=
                1 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={7} className="mt-2" fullWidth>
                      Nenhum cliente encontrado
                    </Grid>
                    <Grid item xs={5} className="d-flex justify-content-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsCadastroPessoaDialogOpen(true)}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  "Digite e pressione Enter"
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  onKeyDown={(e) => onSearchChangePessoas(e, 1)}
                  inputRef={inputCliente}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <React.Fragment>
                          {loadingAutoComplete ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      </>
                    ),
                  }}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                setEnderecoClienteDataGrid([]);
                setEnderecoCliente(null);
                setEnderecoAleterado(null);
                handleChangeAutocomplete("pessoaClienteId", newValue);
                setClienteSelecionado(newValue);
                buscarDadosClientes(newValue?.id);
                buscaEnderecos(newValue?.id);
                buscarTabelasPrecoCliente(
                  newValue?.clientes[0]?.tabelaPrecoPadraoId
                );
              }}
              value={clienteSelecionado}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="pessoaVendedorId"
              name="pessoaVendedorId"
              disabled={id && orcamento.status !== "ABERTO"}
              options={vendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedor"
                  variant="outlined"
                  error={inputErros.get("pessoaVendedorId")}
                  required
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setInputErro([]);
                handleChangeAutocomplete("pessoaVendedorId", newValue);
              }}
              value={value.autoComplete(
                vendedorList,
                orcamento?.pessoaVendedorId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              required
              id="meioPagamentoId"
              name="meiosDePagamento"
              disabled={id && orcamento.status !== "ABERTO"}
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meios de pagamento"
                  variant="outlined"
                  error={inputErros.get("meioPagamentoId")}
                  required
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                handleChangeAutocomplete("meioPagamentoId", newValue);
              }}
              value={value.autoComplete(
                meioPagamentoList,
                orcamento?.meioPagamentoId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="condicaoPagamentoId"
              name="condicaoPagamentoId"
              disabled={id && orcamento.status !== "ABERTO"}
              options={filtrarCondicoesPagamento(pagamentoSelecionado)}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Condição de Pagamento"
                  variant="outlined"
                  error={inputErros.get("condicaoPagamentoId")}
                  required
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                handleChangeAutocomplete("condicaoPagamentoId", newValue);
              }}
              value={value.autoComplete(
                condicaoPagamentoList,
                orcamento?.condicaoPagamentoId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <hr className="mt-4 mb-2" />
      <Paper>
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="ITENS DA NOTA" {...tabProps(0)} />
          <Tab label="DADOS DO CLIENTE" {...tabProps(1)} />
          <Tab label="DETALHES" {...tabProps(2)} />
        </Tabs>
      </Paper>
      <CardContent ref={containerRef}>
        <TabPanel value={abaValue} index={0} dir={theme.direction}>
          <OrcamentosItens
            id={id}
            orcamento={orcamento}
            loadingAutoComplete={loadingAutoComplete}
            timeoutBuscaAutocomplete={timeoutBuscaAutocomplete}
            setLoadingAutoComplete={setLoadingAutoComplete}
            produtoList={produtoList}
            setProdutoList={setProdutoList}
            buscarProdutosSimilares={buscarProdutosSimilares}
            produtosSimilares={produtosSimilares}
            setProdutosSimilares={setProdutosSimilares}
            listaOrcamentosProdutos={listaOrcamentosProdutos}
            setListaOcamentosProdutos={setListaOcamentosProdutos}
            tabelaPrecoCliente={tabelaPrecoCliente}
            removerProdutoCadastrado={removerProdutoCadastrado}
            origensMercadoriasList={origensMercadoriasList}
            listaUnidadesComerciais={listaUnidadesComerciais}
            setListaUnidadesComerciais={setListaUnidadesComerciais}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={1} dir={theme.direction}>
          {abaValue == 1 ? (
            <OrcamentosDadosClientes
              orcamento={orcamento}
              setOrcamento={setOrcamento}
              enderecoAleterado={enderecoAleterado}
              setEnderecoAleterado={setEnderecoAleterado}
              clienteList={clienteList}
              enderecoCliente={enderecoCliente}
              buscarDadosClientes={buscarDadosClientes}
              enderecoClienteDataGrid={enderecoClienteDataGrid}
              buscaEnderecos={buscaEnderecos}
              clienteSelecionado={clienteSelecionado}
              cidadeId={cidadeId}
              setCidadeId={setCidadeId}
              estados={estados}
              endereco={endereco}
              setEndereco={setEndereco}
              salvarEndereco={salvarEndereco}
              inputErros={inputErros}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={2} dir={theme.direction}>
          <OrcamentosCampos
            orcamento={orcamento}
            handleChangeInput={handleChangeInput}
          />
        </TabPanel>
        <FullscreenDialog
          open={isCadastroPessoaDialogOpen}
          handleClose={handleCloseCadastroCliente}
          content={
            <AddPessoas
              match={{ params: { id: null } }}
              isFullscreenDialog
              handleCloseFullscreenDialog={handleCloseCadastroCliente}
            />
          }
        />
      </CardContent>
      <ComissaoVendedorDialog
        itemValorComissaoVendedor={itemValorComissaoVendedor}
        setItemValorComissaoVendedor={setItemValorComissaoVendedor}
        itemPercentualComissaoVendedor={itemPercentualComissaoVendedor}
        setItemPercentualComissaoVendedor={setItemPercentualComissaoVendedor}
        itemTotalComissaoVendedor={itemTotalComissaoVendedor}
        setItemTotalComissaoVendedor={setItemTotalComissaoVendedor}
        listaOrcamentosProdutos={listaOrcamentosProdutos}
      />
    </AddLayout>
  );
};

export default AddOrcamentosView;
