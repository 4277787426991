import { useState, useEffect } from "react";
import shortid from "shortid";
import { AddLayout } from "../../../layouts";
import {
  Autocomplete,
  DatePicker,
  FormControl,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  DataGrid,
  CurrencyInput,
  Checkbox,
} from "../../../components";
import {
  BancosCaixasResponsaveisService,
  BancosCaixasService,
  notification,
  history,
  UsuarioService,
} from "../../../services";
import {
  userLogs,
  responseErros,
  formatDate,
  unformatPrice,
  voltar,
} from "../../../utils";
import { InputErros, value, PermisoesHelper } from "../../../helpers";
import contaValidator from "./middlewares/banco-caixa.validator";
import { BancoCaixa } from "../entities";
import { useImportContext } from "../../../contexts/import.context";

const AddBancosCaixas = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("bancos-caixas-visualizar");
  const id = match && match.params?.id;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [initialContaState, setInitialContaState] = useState({
    tipoConta: "INTERNA",
    aberturaAutomatica: false,
    ativado: true,
  });
  const [conta, setConta] = useState({
    tipoConta: "INTERNA",
    aberturaAutomatica: false,
    ativado: true,
  });
  const [listaResponsaveis, setListaResponsaveis] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const bancosCaixasService = new BancosCaixasService();
  const bancosCaixasResponsaveisService = new BancosCaixasResponsaveisService();
  const usuarioService = new UsuarioService();

  const colunas = [
    {
      field: "usuarioId",
      headerName: "Nome",
      flex: 1000,
      valueGetter: (params) =>
        value.autoComplete(listaUsuarios, params.value).nome,
    },
  ];

  async function buscarDadosIniciais() {
    await buscaConta();
  }

  useEffect(() => {
    if (id) {
      buscarDadosIniciais();
    }
    buscarUsuarios();
  }, []);

  const buscaConta = async () => {
    setLoading(true);
    const result = await bancosCaixasService.getById(id);
    if (!result.isAxiosError) {
      if (!result.data) {
        voltar();
        initialCollapses();
        return;
      }
      setInitialContaState(result.data);
      setConta(result.data);
      setListaResponsaveis(result.data.responsaveisList);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarUsuarios = function () {
    usuarioService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setListaUsuarios(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(conta);
    setUserLog(logs);
  }

  const handleInputChange = (event) => {
    setConta((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    e.persist();
    setConta((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const handleDateChange = (name, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setConta((prevState) => ({
      ...prevState,
      [name]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    const dataBanco = {
      ...conta,
      responsaveisList: listaResponsaveis,
    };
    contaValidator
      .validate(dataBanco, { abortEarly: false })
      .then(async () => {
        const bancoCaixa = new BancoCaixa(dataBanco);
        const emailValido = bancoCaixa.validarEmail();
        if (!emailValido && conta.emailGerente) {
          const erro = {
            errors: ["E-mail inválido"],
            inner: [{ path: "emailGerente" }],
          };
          inputErros.set(erro);
          setLoading(false);
          return false;
        }
        if (conta?.id) {
          const response = await bancosCaixasService.atualizar(
            conta?.id,
            bancoCaixa
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            history.goBack();
          } else {
            responseErros(response);
          }
        } else {
          const response = await bancosCaixasService.cadastrar(bancoCaixa);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              history.goBack();
            } else {
              handleCancelar();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setConta(initialContaState);
  };

  const handleAdicionarUsuario = (idUser) => {
    const usuarioInserido = listaResponsaveis.find(
      (item) => item.usuarioId == idUser
    );
    if (usuarioInserido) {
      notification.alertaGenericos("Usuario já inserido");
    } else {
      setConta({
        ...conta,
        usuarioId: null,
      });
      const novoResponsavel = {
        id: shortid.generate(),
        usuarioId: idUser,
        bancoCaixaId: id,
      };
      if (id) {
        handleSubmitResponsavel(novoResponsavel);
      } else setListaResponsaveis([...listaResponsaveis, novoResponsavel]);
    }
  };

  const handleSubmitResponsavel = async (responsavel) => {
    setLoading(true);
    if (responsavel) {
      const response = await bancosCaixasResponsaveisService.cadastrar(
        responsavel
      );
      if (!response.isAxiosError) {
        buscaConta();
        notification.cadastroSucesso();
      } else responseErros(response);
    }
    setLoading(false);
  };

  const handleRemoverUsuario = async (idUser) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir esse usuário?`
    );
    if (retornoAlerta.isConfirmed) {
      if (id) {
        if (listaResponsaveis?.length > 1) {
          setLoading(true);
          const result = await bancosCaixasResponsaveisService.deletar(idUser);
          if (!result.isAxiosError) {
            buscaConta();
            notification.deletadoSucesso();
          } else responseErros(result);
          setLoading(false);
        } else
          notification.alertaGenericos(
            "Pelo menos 1 responsáveis é obrigatório"
          );
      } else {
        const data = listaResponsaveis.filter((item) => item.id !== idUser);
        setListaResponsaveis(data);
      }
    }
  };

  const onChangeAutocomplete = (name, value) => {
    setConta({
      ...conta,
      [name]: value ? value.id : undefined,
    });
  };

  const AtivadoComponent = ({ conta, setConta }) => {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={conta?.ativado ? conta.ativado : false}
            onChange={() =>
              setConta((conta) => {
                return {
                  ...conta,
                  ativado: !conta?.ativado,
                };
              })
            }
            name="ativado"
            color="primary"
          />
        }
        label={conta?.ativado ? "Ativado" : "Desativado"}
      />
    );
  };

  return (
    <AddLayout
      id={id}
      title={conta?.id ? "Editar Caixa" : "Cadastrar Caixa"}
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={conta?.id ? userLog : null}
      onClickLog={onClickLog}
      actions={<AtivadoComponent conta={conta} setConta={setConta} />}
      disableAtualizar={!permissoesHelper.temPermisao("bancos-caixas-editar")}
    >
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            id="nome"
            name="nome"
            label="Nome Conta"
            variant="outlined"
            margin="normal"
            value={value.text(conta?.nome)}
            onChange={(e) => {
              setInputErro([]);
              handleInputChange(e);
            }}
            fullWidth
            required
            error={inputErros.get("nome")}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataSaldoInicial"
            name="dataSaldoInicial"
            label="Data Saldo Inicial"
            format="dd/MM/yyyy"
            margin
            value={value.date(conta?.dataSaldoInicial)}
            onChange={(date) => handleDateChange("dataSaldoInicial", date)}
            error={inputErros.get("dataSaldoInicial")}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="saldoInicial"
            name="saldoInicial"
            label="Saldo Inicial (R$)"
            variant={id ? "filled" : "outlined"}
            disabled={id ? true : false}
            margin="normal"
            value={conta.saldoInicial ?? null}
            onBlur={(e) => {
              setInputErro([]);
              handleInputBlur(e);
            }}
            fullWidth
            error={inputErros.get("saldoInicial")}
          />
        </Grid>
        <Grid item xs={3} className="d-flex">
          <FormControlLabel
            className="aberturaAutomatica"
            control={
              <Checkbox
                checked={
                  conta?.aberturaAutomatica ? conta.aberturaAutomatica : false
                }
                value={conta?.aberturaAutomatica}
                onChange={() => {
                  setConta((prevState) => ({
                    ...prevState,
                    aberturaAutomatica: !conta?.aberturaAutomatica,
                  }));
                }}
                name="aberturaAutomatica"
                color="primary"
              />
            }
            label="Abertura Automática"
          />
        </Grid>
      </Grid>
      <div className="mt-4 mb-2 fw-bold">RESPONSÁVEIS</div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="usuarioId"
              name="usuarioId"
              noOptionsText={"Buscar usuario..."}
              options={listaUsuarios}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Usuários"
                  margin="normal"
                  required
                  variant="outlined"
                  error={inputErros.get("usuarioId")}
                />
              )}
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              filterSelectedOptions
              onChange={(e, newValue) => {
                onChangeAutocomplete("usuarioId", newValue);
              }}
              value={value.autoComplete(listaUsuarios, conta.usuarioId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            className="mt-4"
            variant="contained"
            color="primary"
            onClick={() => {
              handleAdicionarUsuario(conta.usuarioId);
            }}
          >
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            />
            Adicionar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-3">
        <Grid item xs={12}>
          <DataGrid
            columns={colunas}
            rows={listaResponsaveis}
            rowCount={listaResponsaveis.length}
            deleteShow={permissoesHelper.temPermisao([
              "bancos-caixas-cadastrar",
              "bancos-caixas-editar",
            ])}
            onClickDelete={handleRemoverUsuario}
          />
        </Grid>
      </Grid>
    </AddLayout>
  );
};

export default AddBancosCaixas;
