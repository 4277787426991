import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  InputAdornment,
  Typography,
  Radio,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  IconButton,
  Autocomplete,
  DatePicker,
  DataGrid,
  TextFielDecimalNumbers,
  CurrencyInput,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  history,
  notification,
  RegraDescontoService,
  ProdutosService,
  GrupoProdutosService,
} from "../../../services";
import { InputErros, PermisoesHelper } from "../../../helpers";
import {
  responseErros,
  userLogs,
  formatDate,
  formatPrice,
  formatPercentage,
  timeoutBuscaAutocomplete,
  unformatPrice,
  voltar,
} from "../../../utils";
import regraDescontoValidator from "./middlewares/regra-desconto.validator";
import "./regra-desconto.scss";
import { useImportContext } from "../../../contexts/import.context";

const AddRegraDesconto = ({ match }) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [regraDesconto, setRegraDesconto] = useState(null);
  const [tipoCondicao, setTipoCondicao] = useState("Valor mínimo");
  const [diasSemana, setDiasSemana] = useState([]);
  const [aplicarDescontoEm, setAplicarDescontoEm] = useState("Percentual");
  const [produtos, setProdutos] = useState([]);
  const [grupoProdutos, setGrupoProdutos] = useState([]);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [listaGruposProdutos, setListaGruposProdutos] = useState([]);
  const [listaRegraDescontoProdutos, setListaRegraDescontoProdutos] = useState(
    []
  );
  const [deletedRegraDescontoProdutoIds, setDeletedRegraDescontoProdutoIds] =
    useState([]);
  const [totalListaProdutos, setTotalListaProdutos] = useState(0);
  const [userLog, setUserLog] = useState({});
  const [openDescontoEditDialog, setOpenDescontoEditDialog] = useState(false);
  const [produtoIdDesconto, setProdutoIdDesconto] = useState(null);
  const [descontoInputError, setDescontoInputError] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const regraDescontoService = new RegraDescontoService();
  const produtosService = new ProdutosService();
  const grupoProdutosService = new GrupoProdutosService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("regras-desconto-visualizar");
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 500,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 800,
    },
    {
      field: "precoVenda",
      headerName: "Preço atual",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "desconto",
      headerName: "Desconto",
      flex: 500,
      valueGetter: (params) => {
        const data = regraDesconto?.regraDescontoProdutos.find(
          (produto) => produto.desconto === params.value
        );
        if (data) {
          return data?.tipoDesconto === "Valor"
            ? formatPrice(parseFloat(params.value))
            : formatPercentage(params.value);
        }
      },
    },
    {
      field: "precoPromocao",
      headerName: "Preço promoção",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
  ];

  async function buscarDadosIniciais() {
    await buscaRegraDesconto();
    buscaGruposProdutos();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  const buscaRegraDesconto = async () => {
    if (!id) return;
    setLoading(true);
    const { data } = await regraDescontoService.getById(id);
    if (!data) {
      initialCollapses();
      voltar();
      return;
    }
    setListaRegraDescontoProdutos(data.regraDescontoProdutos);
    setRegraDesconto(data);
    setTipoCondicao(data.tipoCondicao);
    setAplicarDescontoEm(data.tipoDesconto);
    setDiasSemana(JSON.parse(data.diasSemana));
    const produtosIds = data.regraDescontoProdutos.map(
      (regraDescontoProduto) => {
        return regraDescontoProduto.produtoId;
      }
    );
    buscaRelacaoProdutos(produtosIds);
    setLoading(false);
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(regraDesconto);
    setUserLog(logs);
  }

  const buscaRelacaoProdutos = (produtosIds) => {
    produtosService.getAll({ id: produtosIds }).then((result) => {
      if (!result.isAxiosError) {
        const produtos = result.data.rows;
        setListaRegraDescontoProdutos((prevState) =>
          prevState.map((regraDescontoProduto) => {
            const item = produtos.find((produto) => {
              return produto.id === regraDescontoProduto.produtoId;
            });
            return {
              ...regraDescontoProduto,
              codigo: item.codigo,
              nome: item.descricao,
              precoVenda: item.precoVenda,
              precoPromocao:
                regraDescontoProduto.tipoDesconto === "Valor"
                  ? item.precoVenda - regraDescontoProduto.desconto
                  : item.precoVenda -
                    item.precoVenda * (regraDescontoProduto.desconto / 100),
            };
          })
        );
      }
    });
  };

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    produtosService.getAll(filtro).then((result) => {
      setLoadingAutoComplete(false);
      if (!result.isAxiosError) {
        setListaProdutos(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaGruposProdutos = async () => {
    const result = await grupoProdutosService.getAllAssociations();
    if (!result.isAxiosError) {
      setListaGruposProdutos(result.data);
    } else {
      responseErros(result);
    }
  };

  const onInputChangeProdutos = (id) => {
    const eventValue = document.getElementById(id).value;
    if (eventValue?.length >= 2) {
      const filtros = {
        codigo: eventValue,
        codigoBarra: eventValue,
        descricao: eventValue,
      };
      setLoadingAutoComplete(true);
      timeoutBuscaAutocomplete(buscarProdutos(filtros));
    }
  };

  const handleInputChange = (e) => {
    e.persist();
    setRegraDesconto((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    e.persist();
    setRegraDesconto((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const handleDateChange = (targetName, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setRegraDesconto((prevState) => ({
      ...prevState,
      [targetName]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    }));
  };

  const handleRadioTipoCondicao = (e) => {
    setTipoCondicao(e.target.value);
  };

  const handleRadioDiaSemana = (e) => {
    const alreadySelected = diasSemana.find((dia) => dia === e.target.value);
    if (alreadySelected) {
      return setDiasSemana([
        ...diasSemana.filter((dia) => dia !== e.target.value),
      ]);
    }
    setDiasSemana((prevState) => [...prevState, e.target.value]);
  };

  const handleRadioAplicarDescontoEm = (e) => {
    setAplicarDescontoEm(e.target.value);
  };

  const handleAplicarFiltro = async () => {
    if (!regraDesconto?.desconto) {
      setDescontoInputError(true);
      return notification.erroValidacao("Campo desconto tabela");
    }

    if (produtos.length === 0 && grupoProdutos.length === 0) {
      return notification.erroValidacao(
        "Selecione ao menos um produto ou grupo de produtos."
      );
    }

    grupoProdutos.forEach((grupo) => {
      grupo?.produtos?.forEach((produto) => {
        setListaRegraDescontoProdutos((prevState) => [
          ...prevState,
          {
            id: produto.id,
            produtoId: produto.id,
            regraDescontoId: regraDesconto?.id,
            codigo: produto.codigo,
            nome: produto.descricao,
            precoVenda: produto.precoVenda,
            tipoDesconto: aplicarDescontoEm,
            desconto: regraDesconto?.desconto,
            precoPromocao:
              aplicarDescontoEm === "Percentual"
                ? produto.precoVenda -
                  produto.precoVenda * (regraDesconto.desconto / 100)
                : produto.precoVenda - regraDesconto.desconto,
          },
        ]);
      });
    });

    produtos.forEach((produto) => {
      if (produto) {
        setListaRegraDescontoProdutos((prevState) => [
          ...prevState,
          {
            id: produto.id,
            produtoId: produto.id,
            regraDescontoId: regraDesconto?.id,
            codigo: produto.codigo,
            nome: produto.descricao,
            precoVenda: produto.precoVenda,
            tipoDesconto: aplicarDescontoEm,
            desconto: regraDesconto?.desconto,
            precoPromocao:
              aplicarDescontoEm === "Percentual"
                ? produto.precoVenda -
                  produto.precoVenda * (regraDesconto.desconto / 100)
                : produto.precoVenda - regraDesconto.desconto,
          },
        ]);
      }
    });

    setGrupoProdutos([]);
    setProdutos([]);
  };

  const openEditDescontoDialog = async (id) => {
    setOpenDescontoEditDialog(true);
    setProdutoIdDesconto(id);
  };

  const closeEditDescontoDialog = async () => {
    setOpenDescontoEditDialog(false);
  };

  const onClickUpdateDescontoProduto = () => {
    const data = regraDesconto?.regraDescontoProdutos.find((produto) => {
      return produto.id === produtoIdDesconto;
    });

    if (!regraDesconto?.desconto) {
      return notification.erroValidacao("Desconto");
    }

    const newDescontoProduto = {
      ...data,
      tipoDesconto: aplicarDescontoEm,
      desconto: regraDesconto?.desconto,
      precoPromocao:
        aplicarDescontoEm === "Percentual"
          ? data.precoVenda - data.precoVenda * (regraDesconto.desconto / 100)
          : data.precoVenda - regraDesconto.desconto,
    };

    setListaRegraDescontoProdutos((prevState) => [
      ...prevState,
      newDescontoProduto,
    ]);
    closeEditDescontoDialog();
  };

  const onClickDeleteProduto = async function (id) {
    const data = listaRegraDescontoProdutos.filter((item) => item.id !== id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir produto?"
    );
    if (retornoAlerta.isConfirmed) {
      setListaRegraDescontoProdutos(data);
      setDeletedRegraDescontoProdutoIds((prevState) => [...prevState, id]);
    }
  };

  useEffect(() => {
    let filteredTabelaPrecoProdutos = listaRegraDescontoProdutos.reduce(
      (acc, current) => {
        const i = acc.findIndex((item) => item?.id === current?.id);
        if (i !== -1) {
          acc.splice(i, 1);
          return acc.concat([current]);
        } else {
          return acc.concat([current]);
        }
      },
      []
    );

    let produtoCount = 0;
    for (const item of filteredTabelaPrecoProdutos) {
      if (item !== null) produtoCount++;
    }
    setTotalListaProdutos(produtoCount);

    setRegraDesconto((prevState) => ({
      ...prevState,
      diasSemana,
      tipoCondicao,
      tipoDesconto: aplicarDescontoEm,
      regraDescontoProdutos: [...filteredTabelaPrecoProdutos],
      deletedRegraDescontoProdutoIds:
        filteredTabelaPrecoProdutos.length > 0
          ? [...deletedRegraDescontoProdutoIds]
          : [],
    }));
  }, [
    tipoCondicao,
    diasSemana,
    aplicarDescontoEm,
    listaRegraDescontoProdutos,
    deletedRegraDescontoProdutoIds,
  ]);

  const handleSubmit = async () => {
    setLoading(true);
    regraDescontoValidator
      .validate(regraDesconto, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await regraDescontoService.atualizar(
            id,
            regraDesconto
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            history.goBack();
          } else {
            responseErros(response);
          }
        } else {
          const response = await regraDescontoService.cadastrar(regraDesconto);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            history.goBack();
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const Actions = () => {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={regraDesconto?.situacao ? regraDesconto.situacao : false}
            onChange={() =>
              setRegraDesconto((prevState) => ({
                ...prevState,
                situacao: !regraDesconto?.situacao,
              }))
            }
            name="situacao"
            color="primary"
          />
        }
        label={regraDesconto?.situacao ? "Ativado" : "Desativado"}
      />
    );
  };

  return (
    <AddLayout
      onClickSalvar={handleSubmit}
      loading={loading}
      title={
        regraDesconto?.id
          ? "Editar regra de desconto"
          : "Cadastrar regra de desconto"
      }
      actions={<Actions />}
      userLog={regraDesconto?.id ? userLog : null}
      onClickLog={onClickLog}
      codigo={id ? `Código Nº ${regraDesconto?.codigo}` : null}
      disableAtualizar={!permissoesHelper.temPermisao("regras-desconto-editar")}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              variant="outlined"
              margin="normal"
              value={regraDesconto?.nome ? regraDesconto.nome : ""}
              onChange={(e) => {
                setInputErro([]);
                handleInputChange(e);
              }}
              fullWidth
              required
              error={inputErros.get("nome")}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              id="dataInicial"
              name="dataInicial"
              label="Data inicial"
              format="dd/MM/yyyy"
              margin
              value={
                regraDesconto?.dataInicial ? regraDesconto.dataInicial : null
              }
              onChange={(date) => {
                setInputErro([]);
                handleDateChange("dataInicial", date);
              }}
              error={inputErros.get("dataInicial")}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              id="dataFinal"
              name="dataFinal"
              label="Data final"
              format="dd/MM/yyyy"
              margin
              disabled={!regraDesconto?.dataInicial}
              minDate={new Date(regraDesconto?.dataInicial)}
              value={regraDesconto?.dataFinal ? regraDesconto.dataFinal : null}
              onChange={(date) => {
                setInputErro([]);
                handleDateChange("dataFinal", date);
              }}
              error={inputErros.get("dataFinal")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className="tipoCondicaoTipography" item>
            <Typography>Condição:</Typography>
          </Grid>
          <Grid item>
            <Radio
              className="tipoCondicaoRadioButton"
              checked={tipoCondicao === "Valor mínimo"}
              onChange={handleRadioTipoCondicao}
              value="Valor mínimo"
              name="tipoCondicao"
              color="primary"
            />
          </Grid>
          <Grid className="tipoCondicaoTipography" item>
            <Typography>Valor mínimo</Typography>
          </Grid>
          <Grid item>
            <Radio
              className="tipoCondicaoRadioButton"
              checked={tipoCondicao === "Quantidade mínima"}
              onChange={handleRadioTipoCondicao}
              value="Quantidade mínima"
              name="tipoCondicao"
              color="primary"
            />
          </Grid>
          <Grid className="tipoCondicaoTipography" item>
            <Typography>Quantidade mínima</Typography>
          </Grid>
          <Grid item xs={4}>
            {tipoCondicao === "Valor mínimo" ? (
              <CurrencyInput
                id="valorCondicao1"
                name="valorCondicao"
                label="Condição"
                value={
                  regraDesconto?.valorCondicao
                    ? regraDesconto.valorCondicao
                    : null
                }
                onBlur={handleInputBlur}
                error={descontoInputError}
              />
            ) : (
              <TextField
                id="valorCondicao2"
                name="valorCondicao"
                label="Condição"
                margin="normal"
                variant="outlined"
                fullWidth
                value={
                  regraDesconto?.valorCondicao
                    ? regraDesconto.valorCondicao
                    : null
                }
                onChange={(e) => {
                  setDescontoInputError(false);
                  handleInputChange(e);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">UN.</InputAdornment>
                  ),
                }}
                error={descontoInputError}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="diasSemanaContainer" container spacing={1}>
          <Grid className="diasSemana" item>
            <Typography>Dias da semana:</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              checked={diasSemana?.find((dia) => dia === "Dom") ? true : false}
              onClick={handleRadioDiaSemana}
              color="primary"
              value="Dom"
              name="diasSemana"
            />
          </Grid>
          <Grid className="diasSemana" item>
            <Typography>Dom</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              checked={diasSemana?.find((dia) => dia === "Seg") ? true : false}
              onClick={handleRadioDiaSemana}
              color="primary"
              value="Seg"
              name="diasSemana"
            />
          </Grid>
          <Grid className="diasSemana" item>
            <Typography>Seg</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              checked={diasSemana?.find((dia) => dia === "Ter") ? true : false}
              onClick={handleRadioDiaSemana}
              color="primary"
              value="Ter"
              name="diasSemana"
            />
          </Grid>
          <Grid className="diasSemana" item>
            <Typography>Ter</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              checked={diasSemana?.find((dia) => dia === "Qua") ? true : false}
              onClick={handleRadioDiaSemana}
              color="primary"
              value="Qua"
              name="diasSemana"
            />
          </Grid>
          <Grid className="diasSemana" item>
            <Typography>Qua</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              checked={diasSemana?.find((dia) => dia === "Qui") ? true : false}
              onClick={handleRadioDiaSemana}
              color="primary"
              value="Qui"
              name="diasSemana"
            />
          </Grid>
          <Grid className="diasSemana" item>
            <Typography>Qui</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              checked={diasSemana?.find((dia) => dia === "Sex") ? true : false}
              onClick={handleRadioDiaSemana}
              color="primary"
              value="Sex"
              name="diasSemana"
            />
          </Grid>
          <Grid className="diasSemana" item>
            <Typography>Sex</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              checked={diasSemana?.find((dia) => dia === "Sáb") ? true : false}
              onClick={handleRadioDiaSemana}
              color="primary"
              value="Sáb"
              name="diasSemana"
            />
          </Grid>
          <Grid className="diasSemana" item>
            <Typography>Sáb</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <hr className="ms-3 me-3 mb-4" />
      <CardHeader title={"Filtro por produtos"} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                multiple
                id="produtoId"
                name="produtoId"
                options={listaProdutos}
                autoHighlight
                loading={loadingAutoComplete}
                loadingText="Carregando"
                noOptionsText="Digite e clique no ícone para buscar"
                getOptionLabel={(option) => {
                  let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                  if (option.codigoBarra) {
                    descricaoProduto += ` - ${option.codigoBarra}`;
                  }
                  return option ? descricaoProduto : "";
                }}
                filterSelectedOptions
                filterOptions={(options, state) =>
                  options.filter((item) =>
                    item.descricao.includes(state.inputValue)
                  )
                }
                onChange={(e, newValue) => {
                  e.persist();
                  setProdutos(newValue);
                  setListaProdutos([]);
                }}
                value={produtos}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Produtos"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => onInputChangeProdutos("produtoId")}
                            >
                              <i
                                className="ph-bold ph-magnifying-glass"
                                style={{ fontSize: 18 }}
                              ></i>
                            </IconButton>
                          </InputAdornment>
                          <React.Fragment>
                            {loadingAutoComplete ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                multiple
                id="grupoProdutoId"
                name="grupoProdutoId"
                options={listaGruposProdutos}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => option?.descricao ?? ""}
                filterSelectedOptions
                onChange={(e, newValue) => {
                  e.persist();
                  setGrupoProdutos(newValue);
                }}
                value={grupoProdutos}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Grupos de produtos"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className="aplicarDescontoEmTypography" item>
            <Typography>Tipo de desconto:</Typography>
          </Grid>
          <Grid item>
            <Radio
              className="aplicarDescontoEmRadioButton"
              checked={aplicarDescontoEm === "Valor"}
              onChange={handleRadioAplicarDescontoEm}
              color="primary"
              value="Valor"
              name="aplicarDescontoEm"
            />
          </Grid>
          <Grid className="aplicarDescontoEmTypography" item>
            <Typography>Valor</Typography>
          </Grid>
          <Grid item>
            <Radio
              className="aplicarDescontoEmRadioButton"
              checked={aplicarDescontoEm === "Percentual"}
              onChange={handleRadioAplicarDescontoEm}
              color="primary"
              value="Percentual"
              name="aplicarDescontoEm"
            />
          </Grid>
          <Grid className="aplicarDescontoEmTypography" item>
            <Typography>Percentual</Typography>
          </Grid>
          <Grid item xs={4}>
            {aplicarDescontoEm === "Valor" ? (
              <CurrencyInput
                id="desconto"
                name="desconto"
                label="Desconto"
                value={regraDesconto?.desconto ? regraDesconto.desconto : null}
                onBlur={(e) => {
                  setDescontoInputError(false);
                  handleInputBlur(e);
                }}
                error={descontoInputError}
              />
            ) : (
              <TextFielDecimalNumbers
                id="desconto2"
                name="desconto"
                label="Desconto"
                margin="normal"
                variant="outlined"
                fullWidth
                value={regraDesconto?.desconto ? regraDesconto.desconto : null}
                onBlur={(e) => {
                  setDescontoInputError(false);
                  handleInputBlur(e);
                }}
                error={descontoInputError}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleAplicarFiltro}
        >
          <i
            className="ph-fill ph-plus-circle"
            style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
          ></i>
          Adicionar produtos
        </Button>
      </CardActions>
      <CardContent className="py-0">
        <Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={listaRegraDescontoProdutos}
              columns={colunas}
              editShow
              deleteShow
              rowCount={totalListaProdutos}
              onClickEdit={openEditDescontoDialog}
              onClickDelete={onClickDeleteProduto}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        PaperComponent="div"
        open={openDescontoEditDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Card className="card">
            <CardHeader
              title={
                regraDesconto?.regraDescontoProdutos.find(
                  (produto) => produto?.id === produtoIdDesconto
                )?.nome
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid className="aplicarDescontoEmTypography" item>
                  <Typography>Aplicar desconto em:</Typography>
                </Grid>
                <Grid item>
                  <Radio
                    className="aplicarDescontoEmRadioButton"
                    checked={aplicarDescontoEm === "Valor"}
                    onChange={handleRadioAplicarDescontoEm}
                    color="primary"
                    value="Valor"
                    name="aplicarDescontoEm"
                  />
                </Grid>
                <Grid className="aplicarDescontoEmTypography" item>
                  <Typography>Valor</Typography>
                </Grid>
                <Grid item>
                  <Radio
                    className="aplicarDescontoEmRadioButton"
                    checked={aplicarDescontoEm === "Percentual"}
                    onChange={handleRadioAplicarDescontoEm}
                    color="primary"
                    value="Percentual"
                    name="aplicarDescontoEm"
                  />
                </Grid>
                <Grid className="aplicarDescontoEmTypography" item>
                  <Typography>Percentual</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {aplicarDescontoEm === "Valor" ? (
                    <CurrencyInput
                      id="desconto"
                      name="desconto"
                      label="Desconto"
                      value={
                        regraDesconto?.desconto ? regraDesconto.desconto : null
                      }
                      onBlur={handleInputBlur}
                    />
                  ) : (
                    <TextFielDecimalNumbers
                      id="desconto2"
                      name="desconto"
                      label="Desconto"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      value={
                        regraDesconto?.desconto ? regraDesconto.desconto : null
                      }
                      onChange={handleInputChange}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={onClickUpdateDescontoProduto}
              >
                <i
                  className="ph-fill ph-check-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Aplicar desconto
              </Button>
              <Button
                variant="contained"
                color="padrao"
                onClick={closeEditDescontoDialog}
              >
                <i
                  className="ph-fill ph-x-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Fechar
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    </AddLayout>
  );
};

export default AddRegraDesconto;
