import { useEffect, useRef, useState } from "react";
import { AddLayout } from "../../../layouts";
import {
  Autocomplete,
  CardContent,
  CurrencyInput,
  DatePicker,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tab,
  TabPanel,
  Tabs,
  TextField,
} from "../../../components";
import {
  responseErros,
  formatDate,
  formatPrice,
  unformatPrice,
  timeoutBuscaAutocomplete,
  voltar,
  userLogs,
} from "../../../utils";
import {
  CentroCustoService,
  CfopService,
  DocumentoFreteService,
  EnderecoService,
  PessoaService,
  PlanosFinanceirosService,
  notification,
} from "../../../services";
import { InputErros, value, PermisoesHelper } from "../../../helpers";
import { constantes } from "./resources/constantes";
import {
  DocumentoFreteFinanceiro,
  DocumentoFreteNfe,
  DocumentoFretePagamentos,
} from "./tabs";
import { documentoFreteValidator } from "./middlewares";
import { useImportContext } from "../../../contexts/import.context";
import { DocumentoFreteEntity } from "../entities";
import {
  calcularTotal,
  tabProps,
  totalPeso,
  totalValorMercadoria,
} from "../documento-frete";
const { naturezaFreteList, tipoFreteList, modalidadeFreteList } = constantes;

const AddDocumentoFreteView = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("documentos-frete-visualizar");
  const { initialCollapses } = useImportContext();
  const pessoaService = new PessoaService();
  const cfopService = new CfopService();
  const enderecoService = new EnderecoService();
  const planosFinanceirosService = new PlanosFinanceirosService();
  const centroCustoService = new CentroCustoService();
  const documentoFreteService = new DocumentoFreteService();

  const { id } = match.params;
  const inputRefCfop = useRef();
  const [documentoFrete, setDocumentoFrete] = useState({
    especie: 0,
    modeloDocumento: "57",
    movimentacaoFinanceira: true,
    modalidadeFrete: modalidadeFreteList[0],
    dataEmissao: formatDate.toSend(new Date()),
  });
  const [pagamentos, setPagamentos] = useState([]);
  const [abaValue, setAbaValue] = useState(0);
  const [cfopList, setCfopList] = useState([]);
  const [listaNfe, setListaNfe] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [transportadorList, setTransportadorList] = useState([]);
  const [estadosList, setEstadosList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [planosFinanceirosList, setPlanosFinanceirosList] = useState([]);
  const [centroCustoList, setCentroCustoList] = useState([]);
  const [loadingAutoCompleteCfop, setLoadingAutoCompleteCfop] = useState(false);
  const [cfop, setCfop] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscaDadosIniciais();
  }, []);

  async function buscaDadosIniciais() {
    await buscarDocumentoFrete();
    buscaEstados();
    buscarTransportadores();
    buscarCentroCusto();
    buscarPlanosFinanceiros();
    setLoading(false);
  }

  const buscarDocumentoFrete = async () => {
    if (!id) return;
    setLoading(true);
    const result = await documentoFreteService.getById(id);
    if (!result.isAxiosError) {
      if (!result.data) {
        initialCollapses();
        voltar();
        return;
      }
      setDocumentoFrete(result.data);
      setListaNfe(result.data?.listaNfe);
      setPagamentos(result.data?.pagamentos);
      buscarCfopById(result.data?.cfopId);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarCentroCusto = () => {
    const filtro = {
      nonPaginated: true,
    };
    centroCustoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setCentroCustoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(documentoFrete);
    setUserLog(logs);
  }

  const buscarPlanosFinanceiros = () => {
    const filtro = {
      nonPaginated: true,
    };
    planosFinanceirosService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setPlanosFinanceirosList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCfops = async (filtros) => {
    const filtro = {
      ...filtros,
      limite: 20,
    };
    cfopService.getAll(filtro).then((result) => {
      setLoadingAutoCompleteCfop(false);
      if (!result.isAxiosError) {
        setCfopList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCfopById = async (id) => {
    cfopService.getById(id).then((result) => {
      if (!result.isAxiosError) {
        setCfop(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaEstados = async () => {
    enderecoService.getEstados().then((res) => {
      if (!res.isAxiosError) {
        setEstadosList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarTransportadores = () => {
    const filtro = {
      ativado: true,
      nonPaginated: true,
      tiposPessoaId: 3,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setTransportadorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleDateChange = (fieldName, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setDocumentoFrete((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : undefined,
    }));
  };

  const handleAutoComplete = (name, value) => {
    const documentoFreteTemp = {
      ...documentoFrete,
      [name]: value ? value.id : undefined,
    };
    setDocumentoFrete(documentoFreteTemp);
  };

  const handleInputChange = (e) => {
    const documentoFreteTemp = {
      ...documentoFrete,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === "modeloDocumento" && e.target.value === "00") {
      documentoFreteTemp.tipoFrete = tipoFreteList[4];
    }
    setDocumentoFrete(documentoFreteTemp);
  };

  const handleInputBlur = (e) => {
    const documentoFreteTemp = {
      ...documentoFrete,
      [e.target.name]: unformatPrice(e.target.value),
    };
    setDocumentoFrete(documentoFreteTemp);
  };

  const onInputChangeCfop = (event) => {
    if (
      event.key === "Enter" &&
      inputRefCfop.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          descricao: eventValue,
        };
        setLoadingAutoCompleteCfop(true);
        timeoutBuscaAutocomplete(buscarCfops(filtros));
      }
    }
  };

  useEffect(() => {
    setDocumentoFrete((prevState) => ({
      ...prevState,
      valorMercadoria: totalValorMercadoria(listaNfe),
      pesoBrutoTotal: totalPeso(listaNfe),
    }));
  }, [listaNfe]);

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    const documentoFreteCadastrar = {
      ...documentoFrete,
      cfopId: cfop.id,
      listaNfe,
      pagamentos,
    };
    documentoFreteValidator
      .validate(documentoFreteCadastrar, { abortEarly: false })
      .then(async () => {
        const documentoFreteEntity = new DocumentoFreteEntity(
          documentoFreteCadastrar
        );
        if (id) {
          const response = await documentoFreteService.atualizar(
            id,
            documentoFreteEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            initialCollapses();
            voltar();
          } else {
            responseErros(response);
          }
        } else {
          const response = await documentoFreteService.cadastrar(
            documentoFreteEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            if (indiceSelecionado === 0) {
              notification.cadastroSucesso();
              initialCollapses();
              voltar();
            } else {
              setDocumentoFrete({
                especie: 0,
              });
              setListaNfe([]);
            }
          } else responseErros(response);
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  return (
    <AddLayout
      id={id}
      title={id ? "Editar Documento de Frete" : "Cadastrar Documento de Frete"}
      onClickSalvar={handleSubmit}
      loading={loading}
      disableAtualizar={
        !permissoesHelper.temPermisao("documentos-frete-editar")
      }
      userLog={id && userLog}
      onClickLog={onClickLog}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DatePicker
            id="dataEmissao"
            name="dataEmissao"
            label="Data Emissão"
            format="dd/MM/yyyy"
            margin
            required
            value={
              documentoFrete?.dataEmissao ? documentoFrete.dataEmissao : null
            }
            onChange={(date) => handleDateChange("dataEmissao", date)}
            error={inputErros.get("dataEmissao")}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataSaida"
            name="dataSaida"
            label="Data Saída"
            format="dd/MM/yyyy"
            margin
            value={documentoFrete?.dataSaida ? documentoFrete.dataSaida : null}
            onChange={(date) => handleDateChange("dataSaida", date)}
            error={inputErros.get("dataSaida")}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataChegada"
            name="dataChegada"
            label="Data Chegada"
            format="dd/MM/yyyy"
            margin
            value={
              documentoFrete?.dataChegada ? documentoFrete.dataChegada : null
            }
            onChange={(date) => handleDateChange("dataChegada", date)}
            error={inputErros.get("dataChegada")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="serieDocumento"
            name="serieDocumento"
            type="number"
            label="Série Documento"
            margin="normal"
            variant="outlined"
            required
            onChange={handleInputChange}
            value={documentoFrete.serieDocumento ?? ""}
            InputProps={{ inputProps: { min: 0 } }}
            error={inputErros.get("serieDocumento")}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="numeroDocumento"
            name="numeroDocumento"
            type="number"
            label="Número Documento"
            margin="normal"
            variant="outlined"
            required
            onChange={handleInputChange}
            value={value.text(documentoFrete?.numeroDocumento)}
            InputProps={{ inputProps: { min: 0 } }}
            error={inputErros.get("numeroDocumento")}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="cfopId"
              name="cfopId"
              options={cfopList}
              autoHighlight
              getOptionLabel={(option) => {
                const optionDescricao = option?.descricao?.replace(".", "");
                return optionDescricao ? optionDescricao : "";
              }}
              filterSelectedOptions
              loading={loadingAutoCompleteCfop}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="CFOP"
                  required
                  error={inputErros.get("cfopId")}
                  onKeyDown={onInputChangeCfop}
                  inputRef={inputRefCfop}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                setCfop(newValue);
              }}
              value={cfop}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Select
            label="Espécie"
            id="especie"
            name="especie"
            variant="filled"
            disabled
            value={documentoFrete?.especie}
            onChange={handleInputChange}
            error={inputErros.get("especie")}
            margin="normal"
          >
            <MenuItem value={0}>Compra</MenuItem>
            <MenuItem value={1}>Venda</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={5}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="modalidadeFrete"
              name="modalidadeFrete"
              options={modalidadeFreteList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Modalidade Frete"
                  variant="outlined"
                  required
                  error={inputErros.get("modalidadeFrete")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setDocumentoFrete((prevState) => ({
                  ...prevState,
                  modalidadeFrete: newValue,
                }));
              }}
              value={documentoFrete.modalidadeFrete ?? ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <Select
            label="Modelo"
            id="modeloDocumento"
            name="modeloDocumento"
            margin="normal"
            required
            value={value.text(documentoFrete?.modeloDocumento)}
            onChange={handleInputChange}
            error={inputErros.get("modeloDocumento")}
          >
            <MenuItem value={"00"}>
              00 - Conhecimento de frete eletrônico
            </MenuItem>
            <MenuItem value={"57"}>
              57 - Conhecimento de frete não fiscal
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              required
              id="tipoFrete"
              name="tipoFrete"
              disabled={documentoFrete?.modeloDocumento === "00"}
              options={tipoFreteList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo Frete"
                  variant={
                    documentoFrete?.modeloDocumento === "00"
                      ? "filled"
                      : "outlined"
                  }
                  error={inputErros.get("tipoFrete")}
                  required
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setDocumentoFrete((prevState) => ({
                  ...prevState,
                  tipoFrete: newValue,
                }));
              }}
              value={documentoFrete?.tipoFrete ? documentoFrete.tipoFrete : ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="chave"
            name="chave"
            label="Chave"
            required
            variant="outlined"
            margin="normal"
            InputProps={{ inputProps: { min: 0, maxLength: 44 } }}
            value={documentoFrete?.chave ? documentoFrete.chave : ""}
            onChange={handleInputChange}
            error={inputErros.get("chave")}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              required
              id="naturezaFrete"
              name="naturezaFrete"
              options={naturezaFreteList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Natureza Frete"
                  variant={"outlined"}
                  error={inputErros.get("naturezaFrete")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setDocumentoFrete((prevState) => ({
                  ...prevState,
                  naturezaFrete: newValue,
                }));
              }}
              value={documentoFrete.naturezaFrete}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              required
              id="transportadorId"
              name="transportadorId"
              options={transportadorList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option ? option.nomeRazaoSocial : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Transportador"
                  variant={"outlined"}
                  error={inputErros.get("transportadorId")}
                  required
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                handleAutoComplete("transportadorId", newValue);
              }}
              value={value.autoComplete(
                transportadorList,
                documentoFrete?.transportadorId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              id="ufId"
              name="ufId"
              options={estadosList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.sigla ? option.sigla : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="UF"
                  variant={"outlined"}
                  error={inputErros.get("ufId")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                handleAutoComplete("ufId", newValue);
              }}
              value={value.autoComplete(estadosList, documentoFrete?.ufId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="rntrc"
            name="rntrc"
            label="RNTRC"
            margin="normal"
            variant="outlined"
            onChange={handleInputChange}
            value={documentoFrete?.rntrc ? documentoFrete.rntrc : ""}
            error={inputErros.get("rntrc")}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorFrete"
            name="valorFrete"
            label="Valor Frete (R$)"
            variant="outlined"
            required
            value={documentoFrete?.valorFrete ? documentoFrete.valorFrete : ""}
            error={inputErros.get("valorFrete")}
            onBlur={handleInputBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorSeguro"
            name="valorSeguro"
            label="Valor Seguro (R$)"
            variant="outlined"
            required
            value={
              documentoFrete?.valorSeguro ? documentoFrete.valorSeguro : ""
            }
            error={inputErros.get("valorSeguro")}
            onBlur={handleInputBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="pesoBrutoTotal"
            name="pesoBrutoTotal"
            label="Peso Bruto Total (Kg)"
            variant="filled"
            margin="normal"
            value={
              documentoFrete?.pesoBrutoTotal
                ? documentoFrete.pesoBrutoTotal
                : ""
            }
            error={inputErros.get("pesoBrutoTotal")}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorMercadoria"
            name="valorMercadoria"
            label="Valor Mercadoria (R$)"
            variant="filled"
            value={
              documentoFrete?.valorMercadoria
                ? documentoFrete.valorMercadoria
                : ""
            }
            error={inputErros.get("valorMercadoria")}
            onBlur={handleInputBlur}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Paper
            className="mt-3 bg-primary py-2 px-2 text-white text-center"
            elevation={4}
          >
            Valor Total
            <br />
            <span className="fs-6">
              {formatPrice(calcularTotal(documentoFrete)) || "R$ 0,00"}
            </span>
          </Paper>
        </Grid>
      </Grid>
      <Paper className="mt-4">
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="ITENS" {...tabProps(0)} />
          <Tab
            disabled={
              !documentoFrete?.valorFrete ||
              !documentoFrete?.valorSeguro ||
              !listaNfe.length
            }
            label="PAGAMENTOS"
            {...tabProps(1)}
          />
          <Tab label="FINANCEIRO" {...tabProps(2)} />
        </Tabs>
      </Paper>
      <CardContent>
        <TabPanel value={abaValue} index={0}>
          <DocumentoFreteNfe
            documentoFreteId={id}
            listaNfe={listaNfe}
            setListaNfe={setListaNfe}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={1}>
          <DocumentoFretePagamentos
            documentoFreteId={id}
            documentoFrete={documentoFrete}
            pagamentos={pagamentos}
            setPagamentos={setPagamentos}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={2}>
          <DocumentoFreteFinanceiro
            documentoFrete={documentoFrete}
            setDocumentoFrete={setDocumentoFrete}
            planosFinanceirosList={planosFinanceirosList}
            centroCustoList={centroCustoList}
          />
        </TabPanel>
      </CardContent>
    </AddLayout>
  );
};

export default AddDocumentoFreteView;
